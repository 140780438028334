<template>
    <div class="Home">
        <div class="home-block">
            <Head from="home"></Head>
            <div class="container content">
                <MainMenu ref="MainMenu"></MainMenu>
                <div class="mainContent">
                    <div class="wrap"
                         v-loading="load"
                         element-loading-background="rgba(255, 255, 255, 0.5)">
                        <div class="search-block">
                            <div class="filter" :class="{'mt-4': total === 0}">
                                <div class="subtitle">
                                    {{$t('label.personSearch')}}
                                    <!--div class="total">
                                        {{$t('label.totalSearch')}}: <span class="number">{{total}}</span>
                                    </div-->
                                </div>
                                <div class="predicate">
                                    <el-input v-model="filter.predicate"
                                              clearable
                                              @keyup.enter.native="handleSearch"
                                              :placeholder="$t('label.predicatePlaceholder')">
                                        <div slot="append" v-if="total">{{$t('total')}}: {{total}}</div>
                                    </el-input>
                                    <!--el-input v-model="filter.sName"
                                              clearable
                                              @keyup.enter.native="handleSearch"
                                              :placeholder="$t('label.sName')">
                                    </el-input>
                                    <el-input v-model="filter.fName"
                                              clearable
                                              @keyup.enter.native="handleSearch"
                                              :placeholder="$t('label.fName')">
                                    </el-input>
                                    <el-input v-model="filter.mName"
                                              clearable
                                              @keyup.enter.native="handleSearch"
                                              :placeholder="$t('label.mName')">
                                    </el-input-->
                                    <el-button type="primary" icon="fal fa-search" @click="handleSearch"></el-button>
                                    <el-button type="primary" plain icon="fal fa-times"
                                               @click="handleClear"></el-button>
                                </div>

                                <div class="dopFilterToggler" :class="{'active': showAdvSearch}"
                                     @click="showAdvSearch = !showAdvSearch">
                                    <i :class="['far', showAdvSearch ? 'fa-chevron-up' : 'fa-chevron-down']"></i>
                                    {{$t('label.advSearch')}}
                                    <i :class="['far', showAdvSearch ? 'fa-chevron-up' : 'fa-chevron-down']"></i>

                                </div>
                                <div class="dopFilter" v-show="showAdvSearch">
                                    <div class="filter-row">
                                        <div>
                                            <label>{{$t('label.category')}}</label>
                                            <div class="fields">
                                                <el-select v-model="filter.category"
                                                           multiple filterable clearable
                                                           placeholder="">
                                                    <el-option v-for="opt in categories"
                                                               :key="opt.id"
                                                               :label="locale === 'ru' ? opt.pagetitle : opt.longtitle"
                                                               :value="opt.id"
                                                    ></el-option>
                                                </el-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="filter-row">
                                        <div>
                                            <label>{{$t('label.profStat')}}</label>
                                            <div class="fields">
                                                <el-input v-model="filter.profStat"
                                                          @keyup.enter.native="handleSearch"
                                                          clearable></el-input>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="filter-row">
                                        <div>
                                            <label>{{$t('label.bDate')}}</label>
                                            <div class="fields">
                                                <el-date-picker
                                                        v-model="filter.bDateFrom"
                                                        type="year"
                                                        format="yyyy"
                                                        clearable
                                                        size="small"
                                                        :default-value="new Date('1890-01-01')"
                                                        :placeholder="$t('from')">
                                                </el-date-picker>
                                                <el-date-picker
                                                        v-model="filter.bDateTo"
                                                        format="yyyy"
                                                        type="year"
                                                        clearable
                                                        size="small"
                                                        :default-value="new Date('1960-12-31')"
                                                        :placeholder="$t('to')">
                                                </el-date-picker>
                                            </div>
                                        </div>
                                        <div>
                                            <label>{{$t('label.dDate')}}</label>
                                            <div class="fields">
                                                <el-date-picker
                                                        v-model="filter.dDateFrom"
                                                        type="year"
                                                        format="yyyy"
                                                        size="small"
                                                        :default-value="new Date('1890-01-01')"
                                                        :placeholder="$t('from')">
                                                </el-date-picker>
                                                <el-date-picker
                                                        v-model="filter.dDateTo"
                                                        format="yyyy"
                                                        type="year"
                                                        size="small"
                                                        :default-value="new Date('1960-12-31')"
                                                        :placeholder="$t('to')">
                                                </el-date-picker>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="filter-row">
                                        <div>
                                            <label>{{$t('label.bPlace')}}</label>
                                            <div class="fields">
                                                <el-input v-model="filter.bPlace"
                                                          @keyup.enter.native="handleSearch"
                                                          clearable></el-input>
                                            </div>
                                        </div>
                                        <div>
                                            <label>{{$t('label.dPlace')}}</label>
                                            <div class="fields">
                                                <el-input v-model="filter.dPlace"
                                                          @keyup.enter.native="handleSearch"
                                                          clearable></el-input>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="filter-row">
                                        <div>
                                            <label>{{$t('label.grPlace')}}</label>
                                            <div class="fields">
                                                <el-input v-model="filter.grPlace"
                                                          @keyup.enter.native="handleSearch"
                                                          clearable></el-input>
                                            </div>
                                        </div>
                                        <div>
                                            <label>{{$t('label.graveyard')}}</label>
                                            <div class="fields">
                                                <el-input v-model="filter.graveyard"
                                                          @keyup.enter.native="handleSearch"
                                                          clearable></el-input>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-end mt-2" v-if="true">
                                        <el-button type="primary" @click="search()">
                                            <i class="far fa-search me-1"></i>
                                            {{$t('search')}}
                                        </el-button>
                                    </div>
                                </div>

                            </div>
                            <div class="results-block" ref="resultBlock" v-if="total > 0">
                                <PersonList :prsList="prsList"
                                            :filter="filter"
                                            @handleShowPerson="handleShowPerson"
                                            @editPerson="handleEditPerson"
                                            @personDeleted="handleDeletedPerson"/>
                                <el-pagination
                                        small
                                        background
                                        :hide-on-single-page="true"
                                        layout="prev, pager, next"
                                        :total="total"
                                        :page-size=20
                                        @current-change="onChangePage"/>
                                <el-backtop target=".wrap" :right="90" :bottom="10"></el-backtop>

                            </div>
                            <div class="total-data" v-else>
                                <!--p v-html="$t('ourDBIncludes')"></p-->
                                <div class="data">
                                    <div>{{$t('label.totalPersons')}}: {{dataTotal.prs}}
                                    </div>
                                    <div>{{$t('label.totalImages')}}: {{dataTotal.img}}
                                    </div>
                                    <div>{{$t('label.totalGraves')}}: {{dataTotal.grv}}
                                    </div>
                                </div>
                                <div class="text-center" v-if="isAdmin">
                                    <el-button type="primary" @click="dialog.visible = true">
                                        <i class="far fa-th-list mf-1"></i>
                                        Статитика
                                    </el-button>
                                </div>

                                <a href="https://info.flagcounter.com/lqM8" class="counter">
                                    <img src="https://s01.flagcounter.com/count2/lqM8/bg_F5F2ED/txt_000000/border_CCCCCC/columns_3/maxflags_15/viewers_0/labels_0/pageviews_1/flags_0/percent_0/"
                                         alt="Flag Counter" border="0">
                                </a>
                                <div>
                                    <p>
                                        <span v-html="$t('youCanAdd')"></span>
                                        <el-button type="primary" plain size="mini" class=" ms-2"
                                                   @click="handleAddPerson">
                                            <i class="fas fa-user"></i>
                                            <i class="far fa-plus"></i>

                                        </el-button>
                                    </p>
                                    <p>
                                        <span>{{$t('youCanEdit')}}:</span>
                                        <el-button type="primary" plain size="mini" class=" ms-2"
                                                   @click="$router.push('contacts')">
                                            <i class="fas fa-envelope"></i>
                                        </el-button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-drawer
                v-if="isAdmin"
                :title="dialog.title"
                size="30vw"
                direction="rtl"
                :visible.sync="dialog.visible">
            <div v-html="dialog.content" v-if="dialog.content"></div>
            <div v-else>
                <table class="table stats">
                    <tr>
                        <th>#</th>
                        <th><span :class="['sortBy',{active: sortBy.field === 'prs_F'},sortBy.dir]"
                                  @click="handleSortTop('prs_F')">Персона</span>
                        </th>
                        <th><span :class="['sortBy',{active: sortBy.field === 'prs_visits'},sortBy.dir]"
                                  @click="handleSortTop('prs_visits')">Просмотры</span></th>
                        <th><span :class="['sortBy',{active: sortBy.field === 'prs_flowers'},sortBy.dir]"
                                  @click="handleSortTop('prs_flowers')">Цветы</span></th>
                    </tr>
                    <tr v-for="(item,key) in topData">
                        <td>{{key + 1}}</td>
                        <td>
                            <span class="click-like" @click="handleShowPerson(item)">
                                {{item.prs_F}} {{item.prs_I}} {{item.prs_O}}
                            </span>
                        </td>
                        <td>{{item.prs_visits}}</td>
                        <td>{{item.prs_flowers}}</td>
                    </tr>
                </table>

            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialog.visible = false">OK</el-button>
            </div>
        </el-drawer>
    </div>
</template>

<script>
    import MainMenu from "../components/MainMenu";
    import PersonList from "../components/PersonsList";
    import Head from "../components/Head";

    export default {

        name: 'Home',
        components: {Head, PersonList, MainMenu},
        data() {
            return {
                prsList: [],
                total: 0,
                dataTotal: {
                    prs: 0,
                    img: 0,
                    grv: 0
                },
                load: false,
                dialog: {
                    title: "Статистика по персонам",
                    content: "",
                    visible: false
                },
                initFilter: {},
                showAdvSearch: false,
                page: 1,
                topData: [],
                sortBy: {
                    field: 'prs_visits',
                    dir: 'desc'
                },
                filter: {
                    predicate: '',
                    fName: '',
                    sName: '',
                    mName: '',
                    profStat: '',
                    bDateFrom: '',
                    bDateTo: '',
                    bPlace: '',
                    dDateFrom: '',
                    dDateTo: '',
                    dPlace: '',
                    grPlace: '',
                    graveyard: '',
                    category: []
                }
            }
        },
        computed: {
            locale() {
                return this.$store.getters.locale;
            },
            categories() {
                return this.$store.getters.initData.categories;
            },
            isEn() {
                return this.$store.getters.locale === 'en'
            },
            isAdmin() {
                return this.$store.getters.isAdmin;
            },
        },
        mounted() {
            let self = this;
            this.initFilter = _.cloneDeep(this.filter);
            this.$refs.MainMenu.activeIndex = '/';
            document.querySelector('.back.carousel-fade ').classList.add('home');
            // self.$store.dispatch('getInitData').then(() => {
                // setTimeout(() => {
                self.countDown('prs', parseInt(self.$store.getters.initData.total.prs), 0);
                self.countDown('img', parseInt(self.$store.getters.initData.total.img), 0);
                self.countDown('grv', parseInt(self.$store.getters.initData.total.grv), 0);
                self.topData = _.orderBy(this.$store.getters.initData.top.map(it => {
                    it.prs_visits = parseInt(it.prs_visits);
                    it.prs_flowers = parseInt(it.prs_flowers);
                    return it;
                }), self.sortBy.field, 'desc');
            // });
        },
        methods: {
            search() {
                let self = this,
                    query = {
                        data: {
                            mod: 'searchFull',
                            predicate: self.filter.predicate,
                            fName: self.filter.fName,
                            sName: self.filter.sName,
                            mName: self.filter.mName,
                            page: self.page,
                            profStat: self.filter.profStat,
                            bDate: self.filter.bDate,
                            bPlace: self.filter.bPlace,
                            dDate: self.filter.dDate,
                            dPlace: self.filter.dPlace,
                            grPlace: self.filter.grPlace,
                            graveyard: self.filter.graveyard,
                            category: self.filter.category
                        }
                    };
                self.load = true;
                console.log('Filter:', self.filter);
                self.$req(query).then((resp, textStatus, request) => {
                    self.prsList = resp.prs;
                    self.total = resp.total || 0;
                    if (self.$refs.resultBlock) {
                        self.$refs.resultBlock.scrollTo({
                            top: 0,
                            behavior: "smooth"
                        });
                    }
                    setTimeout(() => {
                        self.load = false;
                        // self.showAdvSearch = false;
                        // this.$refs.MainMenu.isCollapse = true;
                    }, 333)
                    // console.log('request', request.getResponseHeader('content-type'),' | textStatus:', textStatus);
                })
            },
            onChangePage(page) {
                this.page = page;
                this.search();
            },
            handleSearch() {
                this.page = 1;
                this.search()
            },
            handleShowPerson(prs) {
                this.personToShow = prs;
                this.$nextTick(() => {
                    this.$store.commit("showPersonCard", prs);
                });
            },
            handleEditPerson(prs) {
                this.personToEdit = prs;
                this.$nextTick(() => {
                    this.$store.commit("editPerson", prs);
                });
            },
            handleAddPerson() {
                this.$store.commit("editPerson", {});
            },
            handleDeletedPerson(prs_id) {
                let self = this;
                self.search();
            },
            handleClear() {
                this.filter = _.cloneDeep(this.initFilter);
                this.search();
            },
            handleSortTop(field) {
                let self = this,
                dir = 'desc';
                if(field !== self.sortBy.field){
                    dir = field === 'prs_F' ? 'asc' : 'desc';
                } else {
                    dir = self.sortBy.dir === 'desc' ? 'asc' : 'desc';
                }
                self.sortBy = {
                    dir: dir,
                    field: field
                };

                self.topData = _.orderBy(_.cloneDeep(this.$store.getters.initData.top), self.sortBy.field, self.sortBy.dir);
            },
            countDown(param, max, current) {
                let self = this;
                setTimeout(() => {
                    if (current < max) {
                        current += Math.floor(Math.random() * max / 10);
                        self.dataTotal[param] = current;
                        self.countDown(param, max, current);
                    } else {
                        self.dataTotal[param] = max;

                    }
                }, 75)
            },

        },
    }
</script>

<style lang="scss">
    @import "../assets/variables";

    .Home {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100vh;
        font-family: $font-title;

        .home-block {
            flex-basis: $homeWidth;
            position: relative;
            min-height: 100vh;
            /*height: 100vh;*/
            overflow-y: hidden;
            display: flex;
            flex-direction: column;
            /*margin-right: 30px;*/
        }

        .sortBy {
            cursor: pointer;

            &.active {
                font-weight: 600;

                &:after {
                    font-family: $font-awesome;
                    display: inline-block;
                    margin-left: 3px;
                    font-weight: 400;
                }

                &.desc:after {
                    content: "\f30c";
                }

                &.asc:after {
                    content: "\f309";
                }
            }
        }

        .stats {
            th {
                border-bottom: $border;
                font-weight: 400;
            }

            td, th {
                padding: 5px;

                &:nth-child(3),
                &:nth-child(4) {
                    text-align: center;
                }
            }
        }

        .Head {
            .title {
                flex: 1;
            }
        }

        .content {
            flex: 1;
        }

        .container {
            margin: 0;
            padding: 0;
        }

        .home {
            display: flex;
        }

        .mainContent {
            flex: 1;
            overflow-y: auto;
            overflow-x: hidden;
        }

        .title-total {
            display: flex;
            flex-direction: column;

            .title {
                font-size: 60px;
                color: #3c3d29;
                font-weight: 900;
                flex: 1;
                display: flex;
                align-items: flex-start;
                width: 500px;
                text-align: left;
                line-height: 1.35;
                margin: 0 0 0 30px;
                -webkit-text-stroke: 1px #fff;
                text-shadow: 1px 2px 1px rgba(0, 0, 0, .75);
            }

        }

        .total-data {
            text-align: center;
            margin: 20px 0 0;
            padding: 0 15px;
            font-weight: 600;
            line-height: 1.6;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            font-size: 22px;

            p {
                margin: 0 0 15px 0;
            }

            .data {
                /*margin-bottom: 15px;*/
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 145px;
                font-size: 28px;
                font-weight: bold;
                text-align: left;
                width: 180px;
                white-space: nowrap;
            }
        }

        .search-block {
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            height: 100%;

            .filter {
                margin-bottom: 10px;
                //font-family: $font-sans;
            }

            .dopFilterToggler {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 9px 15px;
                margin-top: 10px;
                border: 1px solid $color-primary;
                border-radius: 3px;
                color: $color-primary-dark;
                font-weight: 600;
                font-size: 17px;
                text-transform: uppercase;
                cursor: pointer;
                transition: all .15s ease;
                font-family: $font-title;
                letter-spacing: 1.5px;


                &:hover,
                &.active {
                    background: $color-primary;
                    color: #fff;
                }

                &.active {
                    border-radius: 3px 3px 0 0;
                }
            }


            .dopFilter {
                font-size: 14px;
                border: 1px solid $color-primary;
                border-radius: 0 0 3px 3px;
                padding: 10px 15px;
                font-family: $font-sans;

                .filter-row {
                    display: flex;

                    & + .filter-row {
                        margin-top: 10px;
                    }

                    > div {
                        flex: 1;

                        & + div {
                            margin-left: 20px;
                        }
                    }

                    .fields {
                        display: flex;
                        gap: 10px;
                    }

                    .el-date-editor--year {
                        width: auto;
                    }

                    .el-select {
                        width: 100%;
                    }

                    .el-input__inner, .el-input-group__append {
                        font-family: $font-sans;
                    }

                    .el-input-group__append {
                        background: $color-primary-light;
                        color: $color-primary;
                    }
                }
            }

            .predicate {
                display: flex;
                margin: 0 -5px;

                > * {
                    margin: 0 5px;
                }


                .el-input {
                    font-size: 15px;
                }

                .el-input__inner {
                    height: 42px;
                    line-height: 42px;
                }

                ::-webkit-input-placeholder {
                    font-family: $font-title;
                    font-size: 14px;
                    text-transform: uppercase;
                    color: #bbb;
                    font-weight: bold;
                    letter-spacing: .5px;
                }

                ::-moz-placeholder {
                    font-family: $font-title;
                    font-size: 14px;
                    text-transform: uppercase;
                    color: #bbb;
                    font-weight: bold;
                    letter-spacing: .5px;
                }

                /* Firefox 19+ */
                :-moz-placeholder {
                    font-family: $font-title;
                    font-size: 14px;
                    text-transform: uppercase;
                    color: #bbb;
                    font-weight: bold;
                    letter-spacing: .5px;
                }

                /* Firefox 18- */
                :-ms-input-placeholder {
                    font-family: $font-title;
                    font-size: 14px;
                    text-transform: uppercase;
                    color: #bbb;
                    font-weight: bold;
                    letter-spacing: .5px;
                }

                .el-input__inner, .el-input-group__append {
                    font-family: $font-title;
                    font-size: 14px;
                    text-transform: uppercase;
                    font-weight: bold;
                    letter-spacing: 1px;
                }

                .el-input-group__append {
                    //background: $color-primary-light;
                    color: $color-primary;
                }


            }

            .filter {
                .subtitle {
                    font-size: 1.3rem;
                    font-weight: 700;
                    margin-bottom: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    -webkit-text-stroke: 1px #333;
                    letter-spacing: 1.5px;
                }
            }

            .total {
                font-weight: 500;
                font-size: 14px;
                margin-top: 3px;
                font-family: $font-sans;

                .number {
                    @include label;
                    font-size: 13px;
                    margin-left: 2px;
                }
            }

        }

        .results-block {
            font-weight: bold;
            /*max-height: calc(100vh - 245px);*/
            /*overflow-y: auto;*/
        }

        .PersonsList.person_cards .item {
            .title {
                font-size: 20px;
                -webkit-text-stroke: .1px rgba(0, 0, 0, 0.5);
                letter-spacing: .5px;

            }

            .subtitle, .dopField {
                font-weight: bold;
                font-size: 16px;
                -webkit-text-stroke: .1px rgba(0, 0, 0, 0.15);

            }

            .profstat {
                font-size: 16px;
            }

            .img {
                width: 100px;
                margin-right: 20px;

                .el-image {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                }

                img {
                    max-width: 100px;
                    max-height: 120px;
                    min-height: 90px;
                }
            }
        }

        .el-drawer {
            font-family: $font-sans;
            min-width: 700px !important;
        }
        a.counter img{
            height: 0!important;
        }
    }
</style>