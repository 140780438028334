import Vue from 'vue'
import App from './App.vue'
import _ from 'lodash'
import $ from 'jquery'
import moment from 'moment'
import router from './router'
import store from './store'
import TextHighlight from 'vue-text-highlight';
import i18n from './commons/i18n'
import utils from './commons/utils'
import req from './commons/req'
import ElementUi from 'element-ui'
import ElementUiLocale from 'element-ui/lib/locale/lang/ru-RU'
// import 'element-ui/lib/theme-chalk/index.css';

import './assets/rusemig-ui.css'
import './assets/commons.scss'

Vue.config.devtools = true;
Vue.config.productionTip = false

Vue.use(ElementUi, { locale: ElementUiLocale, size: 'small' });
Vue.component('text-highlight', TextHighlight);

window.$ = $;
window._ = _;
window.moment = moment;
moment.locale('ru');
Vue.prototype.$isDev = window.location.host !== 'rusabroad.ru';
Vue.prototype.$hostUrl = 'https://rusabroad.ru/';

new Vue({
    i18n,
    utils,
    $,
    req,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
