import Vue from 'vue'
import _ from 'lodash'
import req from '../../commons/req'

export default {
    state: {
        initData: {
            categories: [],
            grCountries: {
                ru: [],
                en: [],
            },
            total: {
                prs: '0',
                img: '0',
                grv: '0'
            },
            top: [],
            isAdmin: false,
        },
        isAdmin: false

    },
    actions: {
        async getInitData(ctx) {
            let isDev = window.location.host === '127.0.0.1:8080',
                isPerson = window.location.pathname.includes('/person/'),
                query = {
                    data: {
                        mod: 'initData',
                        isPerson: isPerson
                    }
                }
            if (isDev) {
                query.data.isDev = true
            }
            const initData = await req(query);
            ctx.commit('updInitData', initData);
        }
    },
    mutations: {
        updInitData(state, initData) {
            state.initData = initData;
            state.isAdmin = !!initData.isAdmin;
        }
    },
    getters: {
        initData(state) {
            return state.initData;
        },
        isAdmin(state) {
            return state.isAdmin;
        }
    }
}