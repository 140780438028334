<template>
    <el-drawer
            :title="$t('label.personCard')"
            :visible.sync="personCard.show"
            size="60%"
            direction="ltr">
        <person-page></person-page>
    </el-drawer>
</template>

<script>
    import Tags from "./widgets/Tags";
    import PersonPage from "./PersonPage";


    export default {
        name: "PersonCard",
        components: {PersonPage, Tags},
        props: {},
        data() {
            return {
                prs: {},
                load: {
                    card: true
                },
                show: {
                    card: false,
                },
            }
        },
        computed: {
            locale() {
                return this.$store.getters.locale;
            },
            isAdmin() {
                return this.$store.getters.isAdmin;
            },
            personCard() {
                return this.$store.getters.personCard;
            },
            encyclopedia() {
                return this.$store.getters.encyclopediaTree;
            },
        },
        watch: {},
        mounted() {
        },
        filters: {
            date(val) {
                return (val && val.length > 7 && !val.match(/[А-Яа-яA-Za-z]/)) ? moment(val).format('DD MMMM YYYY') : val;
            },
            date2(val) {
                return (val && val.length > 7 && !val.match(/[А-Яа-яA-Za-z]/)) ? moment(val).format('DD MMM YYYY - HH:mm') : val;
            }
        },
        methods: {
            close() {
                this.prs = {};
                this.$store.commit("personCard", {show: false});

            },
            encTitle(id) {
                return ((this.$store.getters.encyclopediaTree.titles || {})[id] || {})[this.locale];
            },
        }
    }
</script>

<style lang="scss">
    @import "../assets/variables";

</style>