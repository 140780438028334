import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/persons',
        name: 'Persons',
        component: () => import('../views/Persons.vue'),
    },
    {
        path: '/person/:id',
        name: 'Person',
        component: () => import('../components/PersonPage'),
    },
    {
        path: '/encyclopedia',
        name: 'Encyclopedia',
        component: () => import('../views/Encyclopedia')
    },
    {
        path: '/graves',
        name: 'Graves',
        component: () => import('../views/Graves.vue')
    },
    {
        path: '/contacts',
        name: 'Contacts',
        component: () => import('../views/Standard.vue'),
        props: {
            title: 'Our contacts',
            alias: 'contacts',
            getTree: true
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
