<template>
    <div class="Head">
        <a href="/" class="title pointer">
            {{$t('label.faces')}}
        </a>
        <div class="lang-contact" @click="handleToggleLocale()">
            <img :src="locale === 'ru' ? ukFlag : ruFlag" :alt="`Locale ${locale}`">
        </div>
    </div>
</template>

<script>
    import i18n from "../commons/i18n";

    export default {
        name: "Head",
        props: {
            from: {
                type: String
            }
        },
        computed: {
            locale() {
                return this.$store.state.locale;
            },
            ruFlag() {
                return this.$isDev ? 'https://rusabroad.ru/images/flag_russia_icon.png' : '/images/flag_russia_icon.png';
            },
            ukFlag() {
                return this.$isDev ? 'https://rusabroad.ru/images/flag_uk_icon.png' : '/images/flag_uk_icon.png';
            }
        },
        data() {
            return {}
        },
        methods: {
            handleToggleLocale() {
                let loc = this.locale === 'ru' ? 'en' : 'ru';
                this.$store.commit('locale', loc);
            },

        }
    }
</script>

<style scoped lang="scss">
    @import "../assets/variables";

    .Head {
        top: 0;
        background: #F1EEE7;
        font-weight: 700;
        /*color: #fff;*/
        padding: 10px 15px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0 1px 7px rgba(0, 0, 0, .17);
        border-bottom: 1px solid #ddd;
        z-index: 200;
        position: relative;
    }

    .title {
        font-size: 40px;
        font-family: $font-title;
        font-weight: bold;
        line-height: 1.15;
        -webkit-text-stroke: 1px #333;
        letter-spacing: 1.5px;
        color: #333;
        text-shadow: 2px 2px 3px rgba(0, 0, 0, .3);

        &:hover {
            text-decoration: none;
        }
    }

    .el-select {
        margin-left: 30px;
        width: 75px;
    }

    .lang-contact {
        display: flex;
        align-items: center;
        margin-right: 10px;
        cursor: pointer;
    }

    @media only screen and (max-width: 1610px) {
        .title {
            font-size: 36px;
        }
    }

    @media only screen and (max-width: 1210px) {
        .title {
            font-size: 30px;
        }
    }


</style>