import req from '../../commons/req'

export default {
    state: {
        encyclopedia: {
            loading: true,
            list: [{id: 0}]
        }
    },
    actions: {
        async getEncyclopedia(ctx, locale) {
            let query = {
                data: {
                    mod: 'get_encyclopedia',
                    locale: locale,
                }
            };
            const data = await req(query);
            const encyclopedia = {
                list: data.list,
                titles: data.titles,
                load: false
            }
            // console.log('Enc:', encyclopedia);
            ctx.commit('updEnc', encyclopedia);
        }
    },
    mutations: {
        updEnc(state, data) {
            state.encyclopedia = data;
        }
    },
    getters: {
        encyclopediaTree(state) {
            return state.encyclopedia;
        }
    }
}