<template>
    <div :class="['mdxPage', `type-${type}`]">
        <div class="pageTitle">
            <div>
                <h1 v-html="pageTitle"></h1>
            </div>
        </div>
        <div class="pic" v-if="type === 'article' && params.image">
            <el-image :src="https + params.image"
                      :preview-src-list="[https + params.image]">
                @error="prs.hide = true">
            </el-image>
        </div>
        <div class="text" v-html="content"></div>
        <div class="links" v-if="links && links.length">
            <div class="subtitle">{{$t('card.linksPrs')}}</div>
            <ul class="link-items dashed">
                <li v-for="link in links" :key="link.lnk_id"
                    @click="handleShowPerson(link.prs)" class="pointer">
                    {{link.name}}
                </li>
            </ul>
        </div>
        <feedback v-if="params.description === 'feedback'"></feedback>


    </div>
</template>

<script>

    import Feedback from "@/components/widgets/Feedback";

    export default {
        name: "MdxPage",
        components: {Feedback},
        props: {
            alias: {
                type: String
            },
            id: {
                type: String
            },
            type: {
                type: String
            },
        },
        data() {
            return {
                pageTitle: '',
                content: '',
                params: {},
                links : []
            }
        },
        computed: {
            locale() {
                return this.$store.getters.locale;
            },
            https() {
                return this.$isDev ? 'https://rusabroad.ru/' : ''
            },
            isEn() {
                return this.$store.getters.locale === 'en'
            }
        },

        mounted() {
            this.refresh();

        },
        watch: {
            locale(newVal) {
                let self = this;
                self.$nextTick(() => {
                    self.refresh();
                });
            },
            alias(newVal){
                this.$nextTick(() => {
                    this.refresh();
                });

            },
            id(newVal){
                this.$nextTick(() => {
                    this.refresh();
                });

            }
        },
        methods: {
            refresh() {
                let self = this;
                if (self.id || self.alias) {
                    self.$utils.getMdxPage({id: self.id, alias: self.alias}).then(resp => {
                        // console.log('mdx resp:', resp);
                        self.pageTitle = self.isEn ? resp.page.longtitle : resp.page.pagetitle;
                        self.content = self.isEn ? resp.page.content_en : resp.page.content;
                        self.params = resp.page;
                        let prs = resp.prs;
                        let names = resp.names;
                        self.links = resp.links.map(it => {
                            return {
                                name: names[it.lnk_left_id][self.locale],
                                id: it.lnk_left_id,
                                prs: prs[it.lnk_left_id],
                            }
                        });
                        self.$emit('pageTitle', self.pageTitle)
                    })
                }
            },
            handleShowPerson(prs) {
                this.$nextTick(() => {
                    this.$store.commit("showPersonCard", prs);
                    this.$store.commit("mdxPage", {show:false});
                });
            },


        },
    }
</script>

<style scoped lang="scss">
    .pic {
        float: right;
        padding: 0 0 10px 20px;
        max-width: 400px;
        text-align: center;
        max-height: 500px;

        .el-image {
            max-width: 400px;
            max-height: 500px;
            height: auto;
        }

    }

    .type-article {
        .pageTitle {
            margin-bottom: 7px;

            h1 {
                font-size: 19px;
            }
        }
    }
    .links{
        margin-top: 30px;
    }
    .subtitle{
        font-weight: 600;
    }
    .link-items {
        font-size: 14px;
        padding: 1px 0 0 15px;

        li {
            & + li {
                margin-top: 10px;
            }
        }
    }
</style>