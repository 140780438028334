<template>
    <div class="Tags">
        <el-tag effect="dark" v-for="(tag, key) in tags" :key="key">{{tag}}</el-tag>
    </div>
</template>

<script>
    export default {
        name: "Tags",
        props: {
            tagType: {
                type: String
            },
            locale: {
                type: String
            },
            ids: {
                type: String
            }
        },
        data() {
            return {
                tags: [],
            }
        },
        watch:{
            locale(){
                this.refresh();
            }
        },
        computed: {
            categories() {
                return this.$store.getters.initData.categories || [];
            },
        },
        mounted() {
            this.refresh();
        },
        methods: {
            refresh() {
                let self = this,
                    _ids = self.ids.trim().split(',');
                self.tags = [];
                _ids.forEach(it => {
                    if (it) {
                        let curCategory = self.categories.find(cat => cat.id === it) || {};
                        self.tags.push((this.locale ? curCategory.pagetitle : curCategory.longtitle));
                    }
                })

            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../../assets/variables";
    .Tags {
        display: flex;
        flex-wrap: wrap;
        margin: 10px 0;
        font-family: $font-sans;
        font-variant: all-small-caps;
        .el-tag{
            margin-right: 5px;
            font-family: $font-sans;
            font-weight: 700;
            font-size: 14px;
            letter-spacing: 0.5px;
        }
    }
</style>