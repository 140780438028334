<template>
    <div class="PersonsList person_cards">
        <div class="item" v-for="(prs, ind) in prsList"
             :key="`${prs.prs_id}-${ind}`"
             v-show="isAdmin || prs.prs_pubstat !== 'need moderation'">
            <div class="img">
                <A href="javascript:"
                   @click="handleShowPerson(prs)">
                    <el-image
                            :src="https + prs.prs_img_thumb">
                        <div slot="error" class="image-error-slot">
                            <img :src="mrX" alt="No photo">
                        </div>
                    </el-image>
                </A>
            </div>
            <div class="text">
                <div class="title">
                    <A :href="`javascript:void(${prs.prs_id})`"
                       @click="handleShowPerson(prs)">
                        <template v-if="locale === 'ru' || !prs.prs_F_en">
                            <text-highlight :queries="[filter.predicate, (filter.sName || '')]"
                                            :highlightClass="'markIt'">
                                {{prs.prs_F}}
                            </text-highlight>
                            <text-highlight :queries="[filter.predicate,(filter.fName || '')]"
                                            :highlightClass="'markIt'">
                                {{prs.prs_I}}
                            </text-highlight>
                            <text-highlight :queries="[filter.predicate, (filter.mName || '')]"
                                            :highlightClass="'markIt'">
                                {{prs.prs_O}}
                            </text-highlight>
                            <template v-if="prs.prs_title">, {{prs.prs_title}}</template>
                        </template>

                        <template v-if="locale === 'en' || !prs.prs_F">
                            <text-highlight :queries="[filter.predicate, (filter.sName || '')]"
                                            :highlightClass="'markIt'">
                                {{prs.prs_F_en}}
                            </text-highlight>
                            <text-highlight :queries="[filter.predicate,(filter.fName || '')]"
                                            :highlightClass="'markIt'">
                                {{prs.prs_I_en}}
                            </text-highlight>
                            <text-highlight :queries="[filter.predicate, (filter.mName || '')]"
                                            :highlightClass="'markIt'">
                                {{prs.prs_O_en}}
                            </text-highlight>

                            <template v-if="prs.prs_title_en">, {{prs.prs_title_en}}</template>
                        </template>

                        <i class="fad fa-eye-slash ms-2" v-if="isAdmin && prs.prs_pubstat === 'need moderation'"></i>

                    </A>
                    <div class="btns">
                        <template v-if="isAdmin">
                            <el-button :title="`edit-${prs.prs_id})`"
                                       type="primary" plain
                                       class="btn-sm"
                                       @click="handleEditPerson(prs)"
                                       title="Изменить персону">
                                <i class="far fa-pencil-alt"></i>
                            </el-button>
                            <el-button type="danger" plain class="btn-sm"
                                       @click="handleDelete(prs)"
                                       title="Удалить персону">
                                <i class="far fa-trash-alt"></i>
                            </el-button>
                        </template>
                        <a class="el-button btn-sm ms-2 el-button--primary el-button--small is-plain"
                           :href="'/person/' + prs.prs_id"
                           target="_blank">
                            <i class="far fa-share"></i>
                        </a>

                    </div>
                </div>
                <div class="subtitle" v-if="prs.prs_name_alt">
                    <text-highlight :queries="[filter.predicate, (filter.sName || '')]" :highlightClass="'markIt'">
                        ({{prs.prs_name_alt}})
                    </text-highlight>
                </div>
                <div class="dopField"
                     v-if="filter && filter.fields && prs[fld]"
                     v-for="(fld,fk) in filter.fields" :key="fk">
                    <text-highlight :queries="filter.predicate" :highlightClass="'markIt'">
                        {{$t('fields.' + fld)}} {{prs[fld]}}
                    </text-highlight>
                </div>
                <div class="years">
                    <template v-if="prs.bYear">{{prs.bYear}}</template>
                    <template v-else>?</template>
                    -
                    <template v-if="prs.grYear">{{prs.grYear}}</template>
                    <template v-else>?</template>
                </div>

                <template v-if="locale === 'ru'">
                    <div class="profstat">
                        <text-highlight :queries="[filter.predicate, (filter.profStat || '')]"
                                        :highlightClass="'markIt'">
                            {{prs.prs_profstat}}
                        </text-highlight>
                    </div>
                    <div class="minibio">{{prs.minibio}}</div>
                </template>
                <template v-else>
                    <div class="profstat">
                        <text-highlight :queries="filter.predicate" :highlightClass="'markIt'">
                            {{prs.prs_profstat_en}}
                        </text-highlight>
                    </div>
                    <div class="minibio" v-if="prs.minibio_en">{{prs.minibio_en}}</div>
                    <div class="minibio" v-else>{{prs.minibio}}</div>
                </template>

                <div class="dopField"
                     v-if="filter && filter.graveyard">
                    <text-highlight :queries="[filter.predicate, (filter.graveyard || '')]" :highlightClass="'markIt'">
                        {{$t('label.graveyard')}}:
                        <template v-if="locale === 'en' && prs['prs_graveyard_en']">
                            {{prs['prs_graveyard_en']}}
                        </template>
                        <template v-else>
                            {{prs['prs_graveyard']}}
                        </template>
                    </text-highlight>
                </div>
                <div class="dopField"
                     v-if="filter && filter.bPlace">
                    <text-highlight :queries="[filter.predicate, (filter.bPlace || '')]" :highlightClass="'markIt'">
                        {{$t('label.bPlace')}}: {{prs.prs_b_town}} {{prs.prs_b_country}}
                    </text-highlight>
                </div>
                <div class="dopField"
                     v-if="filter && filter.dPlace">
                    <text-highlight :queries="[filter.predicate, (filter.dPlace || '')]" :highlightClass="'markIt'">
                        {{$t('label.dPlace')}}: {{prs.prs_death_town}} {{prs.prs_death_country}}
                    </text-highlight>
                </div>
                <div class="dopField"
                     v-if="filter && filter.grPlace">
                    <text-highlight :queries="[filter.predicate, (filter.grPlace || '')]" :highlightClass="'markIt'">
                        {{$t('label.grPlace')}}: {{prs.prs_gr_town}} {{prs.prs_gr_country}}
                    </text-highlight>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PersonList",
        props: {
            prsList: {
                type: Array,
                default: () => ([])
            },
            filter: {
                type: Object,
                default: () => ({
                    predicate: '',
                    fName: '',
                    sName: '',
                    mName: '',
                    profStat: '',
                    bDateFrom: '',
                    bDateTo: '',
                    bPlace: '',
                    dDateFrom: '',
                    dDateTo: '',
                    dPlace: '',
                    graveyard: '',
                    category: '',
                })
            },
        },
        data() {
            return {
                show: {
                    card: false
                },
                https: this.$isDev ? 'https://rusabroad.ru/' : '',
            }
        },
        computed: {
            locale() {
                return this.$store.state.locale;
            },
            isAdmin() {
                return this.$store.getters.isAdmin;
            },
            mrX() {
                return this.$isDev ? 'https://rusabroad.ru/images/avatar1.svg' : '/images/avatar1.svg';
            }
        },
        filters: {
            date(val) {
                return (val.length > 7 && !val.match(/[А-Яа-яA-Za-z ]/)) ? moment(val, "YYYY-M-D").format('DD MMM YYYY') : val;
            }
        },
        methods: {
            handleShowPerson(prs) {
                this.$emit('handleShowPerson', prs);
            },
            handleEditPerson(prs) {
                this.$emit('editPerson', prs);
            },
            handleImgError(e) {
                // console.log('eee', regDAte);
                e.target.src = this.$isDev ? 'https://rusabroad.ru/images/avatar1.svg' : '/images/avatar1.svg';
            },
            handleDelete(prs) {
                let self = this;
                this.$confirm('Вы точно хотите удилить персону? ', 'Внимание!', {
                    confirmButtonText: 'Удалить',
                    confirmButtonClass: 'bg-danger',
                    cancelButtonText: 'Отмена',
                    type: 'warning'
                }).then(() => {
                    this.$req({
                        data: {
                            mod: 'delUni',
                            del_id: prs.prs_id,
                            del_key: 'prs'
                        }
                    }).then(resp => {
                        if (resp.success) {
                            this.$message({
                                type: 'success',
                                message: resp.success
                            });
                            self.$emit('personDeleted', prs.prs_id);
                        } else if (resp.error) {
                            this.$message({
                                type: 'error',
                                message: resp.error
                            });
                        }
                    })
                }).catch(() => {

                });
            },


        }

    }
</script>

<style lang="scss">
    @import "../assets/variables";

    .PersonsList {
        &.person_cards {
            .item {
                margin-bottom: 15px;
                padding-bottom: 15px;
                border-bottom: $border;
                display: flex;
                align-items: flex-start;
                line-height: 2;
                z-index: 300;

                .minibio {
                    line-height: 1.55;
                    font-size: 14px;
                }

                .profstat {
                    font-weight: 600;
                    margin: 5px 0 8px;
                    line-height: 1.35;
                    font-size: 15px;
                }

                .img {
                    width: 150px;
                    flex-shrink: 0;

                    .el-image {
                        width: 100%;
                    }

                    img {
                        max-width: 120px !important;
                        max-height: 160px;
                        border-radius: 4px;
                        height: auto;
                        width: auto;
                        margin: 0 auto;
                        display: block;
                    }
                }

                &:last-child {
                    border: none;
                }

                .title {
                    font-size: 120%;
                    font-weight: 600;
                    line-height: 1.2;
                    margin-bottom: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .btns {
                        margin-left: 10px;
                        white-space: nowrap;
                    }
                }

                .text {
                    width: 100%;
                    z-index: 5;
                }

                .subtitle {
                    line-height: 1;
                    font-size: 14px;
                    font-weight: 600;
                    margin-bottom: 5px;
                }

                .dopField {
                    font-size: 13px;
                    line-height: 1.8;
                    font-weight: 600;

                    .value {
                        font-weight: 400;
                    }
                }
            }
        }

    }
</style>