export default {
    searchPersons: 'Найти персону по имени, фамилии, профессиональному статусу',
    yes: "Да",
    no: "Нет",
    back: 'Назад',
    error: 'Ошибка',
    warning: 'Внимание',
    apply: 'Применить',
    cancel: 'Отменить',
    delete: 'Удалить',
    close: 'Закрыть',
    clean: 'Очистить',
    search: 'Поиск',
    save: 'Сохранить',
    create: 'Создать',
    update: 'Обновить',
    edit: 'Редактировать',
    actions: 'Действия',
    from: 'с',
    to: 'по',
    total: 'Всего',
    found: 'Найдено',
    here: 'Здесь',
    emptyResult: 'Ничего не найдено',
    dragFile: 'Перетащите сюда файл или <em>выберите на диске</em>',
    selectFile: 'Выбрать файл',
    ourDBIncludes: 'Наша база включает в себя:',
    youCanAdd: 'Внести новое имя: ',
    youCanEdit:'Дополнить или исправить',
    print: "Печать",

    label: {
        alphaList: 'Алфавитный справочник',
        faces: 'ЛИЦА русского заРУБЕЖья',
        // faces: 'Главная',
        AZ: "А-Я",
        biosAZ: 'Биографии',
        personSearch: 'Поиск ',
        home: 'Главная',
        changeLang: "English",
        persons: 'Биографии',
        totalPersons: 'Биографии',
        totalImages: 'Фотографии',
        totalGraves: 'Захоронения',
        personCard: 'Карточка персоны',
        editPerson: 'Редактирование персоны',
        addPerson: 'Добавить персону',
        browse: 'Выбрать файл',
        totalSearch: 'Всего',
        wiki: "Энциклопедия",
        graves: "Захоронения",
        contacts: "Контакты",
        predicatePlaceholder: "Фамилия, имя, отчество",
        fName: 'Имя',
        sName: 'Фамилия',
        mName: 'Отчество',
        profStat: 'Профессия',
        bDate:'Дата рождения',
        bPlace: 'Место рождения',
        dDate:'Дата смерти',
        dPlace: 'Место смерти',
        grPlace: 'Место захоронения',
        graveyard: 'Кладбище',
        category: 'Категория',
        advSearch: "Расширенный поиск",
        allCountries: "Все страны",
        personalData: "Персональные данные",
        field: 'Поле',
        russian: 'Русский',
        altName: "Альтернативные варианты имени, фамилии",
        birth: "Рождение",
        dateFormatDesc: "Допустимые форматы: дд.мм.гггг, гггг-мм-дд, гггг",
        town: "Город",
        country: "Страна",
        death: "Смерть",
        burial: "Захоронение",
        grvCoords: "Координаты могилы",
        photosFiles: "Фото и файлы",
        linksToObjects: "Связи с объектами",
        putFlower: "Почтить память",
        send: "Отправить",
        submit: 'Отправить',

    },
    card: {
        category: 'Категория',
        bDate: 'Дата рождения',
        bDateOld: 'Дата рождения (стар.стиль)',
        bPlace: 'Место рождения',
        dPlace: 'Место смерти',
        dDate: 'Дата смерти',
        grPlace: 'Место захоронения',
        grDPlace: 'Место смерти/захоронения',
        graveyard: 'Кладбище',
        grCoords: 'Координаты захоронения',
        bio: 'Биографическая справка',
        biblio: 'Библиография',
        docs: 'Документы',
        unknown: 'неизвестна',
        photoPlace: 'Место снимка',
        photoDate: 'Дата снимка',
        photoAuthor: 'Автор снимка',
        deleteBeforeUpload: 'Чтобы загрузить новое фото необходимо удалить старое',
        fileMaxSize: 'Размер файла не должен превышать',
        imgFormat: 'Изображения принимаются только в форматах JPG, PNG и GIF',
        mainPhoto: 'Главное фото персоны',
        grvPhoto: 'Фото захоронения',
        links: "Связанные объекты",
        linksPrs: "Связанные персоны",
        enterYourContacts: `<p>Данные проходят предварительную модерацию администратором ресурса.<br>
            Введенная Вами персона появится в общей базе после успешной проверки. </p>
        <p>Укажите, пожалуйста, свое имя и <b>корректный</b> e-mail для связи, в случае необходимости каких-либо уточнений.</p>
        <p>Спасибо за участие в проекте!</p>`,
        yourName: "Ваше имя",
        yourEmail: "Ваш e-mail",

    },
    fields: {
        prs_b_country: '',
        prs_b_country_en: '',
        prs_b_town: '',
        prs_b_town_en: '',
        prs_death_country: '',
        prs_death_country_en: '',
        prs_death_town: '',
        prs_death_town_en: '',
        prs_gr_country: '',
        prs_gr_country_en: '',
        prs_gr_town: '',
        prs_gr_town_en: '',
        prs_graveyard: '',
        prs_graveyard_en: '',
        prs_name_alt: '',
        prs_profstat: '',
        prs_profstat_en: '',

    },
    placeholders:{
        addLink: "Выбрать связанный объект",
        imgDesc: "Описание фото, его автор...",
    },
    messages: {
        personCreated: 'Инфомация успешно сохранена',
        addAnotherPerson: 'Биография появится в базе после модерации в течении 10 дней<br><br>' +
            'Хотите добавить еще одну персону?',
        thankYou: "Спасибо",
        messageSent: 'Ваше собщение успешно отправлено',
    },
}
