import req from '../../commons/req'

export default {
    state: {
        alphaData: {
            list: [],
            total: 0,
            load: true
        }
    },
    actions: {
        async getAlphaData(ctx, locale) {
            let query = {
                data: {
                    mod: 'show_alpha_list',
                    locale: locale,
                }
            };
            const data = await req(query);
            const alphaData = {
                list: data.list,
                total: data.total,
                load: false
            }
            ctx.commit('updData', alphaData);
        }
    },
    mutations: {
        updData(state, data) {
            state.alphaData = data;
        }
    },
    getters: {
        alphaData(state) {
            return state.alphaData;
        }
    }
}