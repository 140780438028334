export default {
    searchPersons: 'Find person by name, profession',
    yes: "Yes",
    no: "No",
    back: 'Back',
    error: 'Error',
    warning: 'Warning',
    apply: 'Apply',
    cancel: 'Cancel',
    delete: 'Delete',
    close: 'Close',
    clean: 'Clean',
    search: 'Search',
    save: 'Save',
    create: 'Create',
    update: 'Update',
    edit: 'Edit',
    actions: 'Actions',
    from: 'from',
    to: 'to',
    total: 'Total',
    found: 'Found',
    here: 'Here',
    emptyResult: 'Empty result',
    dragFile: 'Drag file here or <em>browse</em>',
    selectFile: 'Select file',
    ourDBIncludes: 'Our Database includes:',
    youCanAdd: 'Add new person:  ',
    youCanEdit:'Add or correct',
    print: "Print",
    label: {
        alphaList: 'Alphabet list',
        faces: 'Faces of Russian Emigration',
        // faces: 'Home',
        personSearch: 'Search',
        AZ: "A-Z",
        biosAZ: 'Biographies',
        home: 'Home',
        changeLang: "Русский",
        persons: 'Persons',
        totalPersons: 'Persons',
        totalImages: 'Photos',
        totalGraves: 'Graves',
        personCard: 'Person card',
        mdxCard: 'Page',
        editPerson: 'Edit person',
        addPerson: 'Add new person',
        browse: 'Select file',
        totalSearch: 'Total',
        wiki: "Encyclopedia",
        graves: "Graves",
        contacts: "Contacts",
        predicatePlaceholder: "First name, second name, patronymic",
        fName: 'First name',
        sName: 'Family name',
        mName: 'Patronymic',
        profStat: 'Profession',
        bDate:'Birth date',
        bPlace: 'Birth place',
        dDate:'Death date',
        dPlace: 'Death place',
        grPlace: 'Burial place',
        graveyard: 'Cemetery',
        category: 'Category',
        advSearch: "Advanced search",
        allCountries: "All countries",
        personalData: "Personal data",
        field: 'Field',
        russian: 'Russian',
        altName: "Alt names",
        birth: "Birth",
        dateFormatDesc: "Valid data formats: dd.mm.yyyy, yyyy-mm-dd, yyyy",
        town: "City/town",
        country: "Country",
        death: "Death",
        burial: "Burial",
        grvCoords: "Grave coordinates",
        photosFiles: "Photos and files",
        linksToObjects: "Links to objects",
        putFlower: "Leave a flower",
        send: "Send",
        submit: 'Submit',
    },
    card:{
        category: 'Category',
        bDate: 'Birth date',
        bDateOld: 'Birth date (Julian cal.)',
        bPlace: 'Birth place',
        dPlace: 'Death place',
        dDate: 'Death date',
        grPlace: 'Burial place',
        grDPlace: 'Death/Burial place',
        graveyard: 'Cemetery',
        grCoords: 'Grave coords.',
        bio: 'Biographical information',
        biblio: 'Bibliography',
        docs: 'Documents',
        unknown: 'unknown',
        photoPlace:'Place',
        photoDate: 'Date',
        photoAuthor: 'Author',
        deleteBeforeUpload: 'Delete the old photo before uploading the new one',
        fileMaxSize: 'File size can not exceed',
        imgFormat: 'Picture must be JPG, PNG и GIF format',
        mainPhoto: 'Main person photo',
        grvPhoto: 'Grave photo',
        links: "Linked objects",
        linksPrs: "Linked persons",
        enterYourContacts: "Enter your name and email for the feedback please",
        yourName: "Your name",
        yourEmail: "Your email",
    },
    fields:{
        prs_b_country: '',
        prs_b_country_en: '',
        prs_b_town: '',
        prs_b_town_en: '',
        prs_death_country: '',
        prs_death_country_en: '',
        prs_death_town: '',
        prs_death_town_en: '',
        prs_gr_country: '',
        prs_gr_country_en: '',
        prs_gr_town: '',
        prs_gr_town_en: '',
        prs_graveyard: '',
        prs_graveyard_en: '',
        prs_name_alt: '',
        prs_profstat: '',
        prs_profstat_en: '',
    },
    placeholders:{
        addLink: "Select object to link",
        imgDesc: "Photo description, author..."
    },
    messages: {
        personCreated: 'The information was saved successfully.',
        addAnotherPerson: 'The biography will appear in the database after moderation.<br><br>'
         + 'Do you want to add another person?',
        thankYou: "Thank you",
        messageSent: 'Your message was sent successfully',

    }
};
