<template>
    <div id="app" v-loading="loading">
        <template v-if="!loading">
            <router-view @curPage="updateCurPage"/>

            <section class="carousel-fade back" v-if="this.$isDev">
                <div class="item fade"
                     style="background-image: url(https://rusabroad.ru/assets/images/clp/sepia10.jpg);"></div>
                <div class="item fade"
                     style="background-image: url(https://rusabroad.ru/assets/images/clp/rdclp9.jpg);"></div>
                <div class="item fade"
                     style="background-image: url(https://rusabroad.ru/assets/images/clp/sepia1.jpg);"></div>
                <div class="item fade"
                     style="background-image: url(https://rusabroad.ru/assets/images/clp/sepia8.jpg);"></div>
                <div class="item fade"
                     style="background-image: url(https://rusabroad.ru/assets/images/clp/sepia2.jpg);"></div>
                <div class="item fade"
                     style="background-image: url(https://rusabroad.ru/assets/images/clp/sepia4.jpg);"></div>
            </section>
            <PersonCard ref="personCard"/>
            <EditPerson ref="editPerson"/>
            <MdxCard ref="mdxCard"/>
        </template>
    </div>
</template>

<script>
    import i18n from './commons/i18n';
    import PersonCard from './components/PersonCard'
    import EditPerson from "./components/EditPerson";
    import MdxCard from "./components/MdxCard";


    export default {
        name: 'App',
        components: {
            MdxCard,
            PersonCard,
            EditPerson
        },
        data() {
            return {
                activeIndex: '/',
                locale: 'ru',
                personToShow: {},
                loading: true

            }
        },
        computed: {},
        mounted() {
            this.$store.dispatch('getInitData').then(() => {
                this.loading = false;
            });
            //----- BG Slider { -----
            let cItem = document.querySelectorAll('.carousel-fade > div'),
                total = cItem.length,
                i = 1;
            if (total > 1) {
                // cItem[total - 1].classList.remove('fade');
                cItem[0].classList.remove('fade');
                setInterval(function () {
                    i--;
                    if (i === 0) {
                        i = total;
                        [].forEach.call(cItem, function (el) {
                            el.classList.remove("fade");
                        });
                    } else {
                        cItem[i].classList.add('fade');
                    }
                }, 10800);
            }
            //----- } BG Slider -----

            // --- user sid --- {
            let sid = (this.$utils.getCookies() || {}).sid || window.localStorage.getItem('sid');
            if (!sid) {
                let d = new Date().getTime(),
                    b = Math.random(),
                    sid = window.btoa(d + 'rusemig' + b).replace(/=/g, '');
                document.cookie = "sid=" + sid;
                window.localStorage.setItem('sid', sid)
            }
            // }--- user sid ---
        },
        methods: {
            updateCurPage(path) {
                this.activeIndex = path;
            },
        }
    }
</script>

<style lang="scss" scoped>
    #app {
        min-height: 100vh;
    }
</style>
