import Vue from 'vue'
import Vuex from 'vuex'
import init from './modules/init'
import encyclopedia from './modules/encylopedia'
import alpha from './modules/alphaList'
import i18n from "../commons/i18n";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        locale: 'ru',
        personCard: {
            show: false,
            editorShow: false,
            personToShow: {},
            refresh: false,
            editorRefresh: false,
        },
        mdxPage: {
            show: false,
            alias: '',
            id: '',
            load: true,
            refresh: false,
        },
    },
    mutations: {
        locale(state, locale) {
            if (locale !== 'ru' && locale !== 'en') {
                locale = state.locale || 'ru';
            }
            i18n.locale = locale;
            state.locale = locale;
            moment.locale(locale);

        },
        personCard(state, param) {
            let obj = Object.entries(param)[0];
            state.personCard[obj[0]] = obj[1];
        },
        showPersonCard(state, personToShow) {
            state.personCard.personToShow = personToShow;
            state.personCard.refresh = true;
            state.personCard.show = true;
        },
        editPerson(state, personToShow) {
            state.personCard.personToShow = personToShow;
            state.personCard.editorRefresh = true;
        },
        showMdxPage(state, param) {
            if (param.show) {
                state.mdxPage.alias = param.alias;
                state.mdxPage.id = param.id;
            }
            state.mdxPage.show = param.show;
        },
        mdxPage(state, param) {
            let obj = Object.entries(param)[0];
            state.mdxPage[obj[0]] = obj[1];
        },
    },
    modules: {
        init,
        alpha,
        encyclopedia
    },
    getters: {
        locale(state) {
            return state.locale;
        },
        personCard(state) {
            return state.personCard;
        },
        personToShowId(state) {
            return state.personCard.personToShow.prs_id;
        },
        mdxPage(state) {
            return state.mdxPage;
        }
    }
})
