<template>
    <div>
        <el-menu :default-active="activeIndex"
                 :router="true"
                 @select="handleSelectMenu"
                 :collapse="isCollapse"
        >
            <el-menu-item
                    @click="isCollapse = !isCollapse"
            >
                <i :class="['far py-2 fa-fs', isCollapse ? 'fa-bars' : 'fa-arrow-to-right']"></i>
            </el-menu-item>
            <el-menu-item index="/" v-show="activeIndex !== '/'">
                <i class="far fa-search  fa-fs"></i>
                <div slot="title">{{ $t('search') }}</div>
            </el-menu-item>
            <el-menu-item index="persons">
                <i class="a-z">{{ $t('label.AZ') }}</i>
                <div slot="title">{{ $t('label.biosAZ') }}</div>
            </el-menu-item>
            <el-menu-item index="encyclopedia">
                <i class="far fa-books fa-fs"></i>
                <div slot="title">{{ $t('label.wiki') }}</div>
            </el-menu-item>
            <el-menu-item index="graves">
                <i class="far fa-tombstone fa-fs"></i>
                <div slot="title">{{ $t('label.graves') }}</div>
            </el-menu-item>
            <el-menu-item index="contacts">
                <i class="far fa-envelope fa-fs"></i>
                <div slot="title">{{ $t('label.contacts') }}</div>
            </el-menu-item>
            <el-menu-item @click="handleToggleLocale" v-if="false">
                <i>
                    <img :src="locale === 'ru' ? ukFlag : ruFlag" :alt="`Locale ${locale}`">
                </i>
                <div slot="title">{{ $t('label.changeLang') }}</div>
            </el-menu-item>
        </el-menu>

    </div>
</template>

<script>
    import i18n from "../commons/i18n";

    export default {
        name: "MainMenu",
        props: {
            from: {
                type: String
            }
        },
        data() {
            return {
                activeIndex: '/',
                isCollapse: true,
                personToShow: {},
            }
        },
        computed: {
            locale() {
                return this.$store.state.locale;
            },
            isAdmin() {
                return this.$store.getters.isAdmin;
            },
            ruFlag() {
                return this.$isDev ? 'https://rusabroad.ru/images/flag_russia_icon.png' : '/images/flag_russia_icon.png';
            },
            ukFlag() {
                return this.$isDev ? 'https://rusabroad.ru/images/flag_uk_icon.png' : '/images/flag_uk_icon.png';
            }
        },
        methods: {
            handleChangeLocale(locale) {
                this.$store.commit('locale', locale);
                i18n.locale = this.locale = locale;
            },
            handleToggleLocale() {
                let locale = this.locale === 'ru' ? 'en' : 'ru';
                this.$store.commit('locale', locale);
                i18n.locale = this.locale = locale;
            },
            handleSelectMenu(path) {
                // console.log('path', path);
                if (path) {
                    if (path === "/") {
                        document.querySelector('.back.carousel-fade ').classList.add('home');
                    } else {
                        document.querySelector('.back.carousel-fade ').classList.remove('home');
                    }
                }
            },
            updateCurPage(path) {
                this.activeIndex = path;
                if (path === 'persons') {
                    // this.isCollapse = false;
                }
            },
        }

    }
</script>

<style scoped lang="scss">
    @import "../assets/variables";

    .el-menu {
        /*margin-bottom: 30px;*/
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
        border-left: $border;
        z-index: 100;
        height: 100%;
        text-align: left;

        .el-menu-item {
            display: flex;
            align-items: center;
            margin: 20px 0;

            &:hover{
                i {
                    color: $color-primary;
                }
            }

            &.is-active {
                font-weight: 700;
                color: #fff;
                background: $color-primary;
                &:hover{
                    i {
                        color: inherit;
                    }
                }
            }
        }

        img {
            width: 19px;
            transition: all .2s ease;
        }

        i {
            font-style: normal;
            margin-right: 7px;
            font-size: 17px;
            line-height: 1;

            &.a-z {
                font-size: 15px;
                font-weight: 600;
                margin-left: -6px;

            }
        }
    }

    .el-menu--collapse {
        width: 75px;

        img {
            width: 22px;
            opacity: .7;
            &:hover{
                opacity: 1;
            }
        }


        .el-menu-item {
            justify-content: center;
            text-align: center;
        }

        i {
            font-weight: 900;
            font-size: 22px;
            margin-right: 0;

            &.a-z {
                font-size: 18px;
                font-weight: 700;
                margin-left: 0;
            }
        }
    }

    .lang {

    }

</style>