<template>
    <div :class="['PersonCard', {personPage:route_id}]">
        <div class="person-container" v-loading="load.card">
            <!--div class="d-none">rId: {{route_id}} | {{prs.prs_id}}</div-->
            <div class="img">
                <el-image
                        :src="prsImg"
                        :preview-src-list="[prs.prs_img]">
                    <div slot="error" class="image-error-slot">
                        <img :src="prs.prs_img  || mrX" alt="No photo">
                    </div>
                </el-image>

                <el-image v-if="prs.prs_grv_img" :class="{'gotDesc': prs.prs_grv_img_desc }"
                          :src="this.$isDev ? 'https://rusabroad.ru/'  + prs.prs_grv_img : prs.prs_grv_img"
                          :preview-src-list="[prs.prs_grv_img]">
                    <div slot="error" class="image-error-slot"></div>
                </el-image>
                <div class="img-desc" v-if="prs.prs_grv_img_desc">
                    {{prs.prs_grv_img_desc}}
                </div>

                <div class="img-items" v-if="prs.img && prs.img.length">
                    <div class="item" v-for="(img, ik) in prs.img" :key="ik">
                        <el-image :class="{'gotDesc': img.img_title || img.img_author || img.img_desc }"
                                  :src="img.img_src"
                                  :preview-src-list="(prs.imgs || [img.img_src])">
                            <div slot="error" class="image-error-slot"></div>
                        </el-image>

                        <div class="img-desc" v-if="img.img_title || img.img_desc">
                            <div class="title" v-if="img.img_title" v-html="img.img_title"></div>
                            <div v-if="img.img_desc" class="desc textarea" v-html="img.img_desc"></div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="text">
                <template v-if="locale === 'ru' || !prs.prs_F_en">
                    <h2>
                        <div class="flex-center">
                            <div>
                                {{prs.prs_F}} {{prs.prs_I}} {{prs.prs_O}}
                                <i class="fad fa-eye-slash ms-2"
                                   v-if="isAdmin && prs.prs_pubstat === 'need moderation'"></i>
                            </div>
                            <el-button type="primary" plain class="btn-sm ms-2"
                                       @click="handleEditPerson(prs)"
                                       v-if="isAdmin">
                                <i class="far fa-pencil-alt"></i>
                            </el-button>
                            <a class="el-button btn-sm ms-2 el-button--primary el-button--small is-plain"
                               :href="'/person/' + prs.prs_id"
                               target="_blank"
                               v-if="!route_id">
                                <i class="far fa-share"></i>
                            </a>
                        </div>
                        <div class="control">
                            <div class="visits" v-if="isAdmin">
                                <span>{{prs.prs_visits}}</span>
                                <i class="fas fa-eye ms-1"></i>
                            </div>
                            <div @click="handlePutFlower" class="flower">
                                <el-tooltip :content="$t('label.putFlower')">
                                        <span>
                                            <span>{{prs.prs_flowers}}</span>
                                            <img src="/images/rose32.png">
                                        </span>
                                </el-tooltip>
                            </div>
                            <el-tooltip :content="$t('print')">
                                <el-button type="primary" plain @click="printCard()" class="printBtn">
                                    <i class="fas fa-print"></i>
                                </el-button>
                            </el-tooltip>
                        </div>

                    </h2>
                    <h3 class="altName" v-if="prs.prs_name_alt">({{prs.prs_name_alt}})</h3>
                </template>
                <template v-else>
                    <h2>
                        {{prs.prs_F_en}} {{prs.prs_I_en}} {{prs.prs_O_en}}
                        <el-button type="primary" plain class="btn-sm"
                                   @click="handleEditPerson(prs)"
                                   v-if="isAdmin">
                            <i class="far fa-pencil-alt"></i>
                        </el-button>
                    </h2>
                </template>
                <div class="years" v-if="prs.bYear || prs.grYear ">
                    <template v-if="prs.bYear">{{prs.bYear}}</template>
                    <template v-else>?</template>
                    -
                    <template v-if="prs.grYear">{{prs.grYear}}</template>
                    <template v-else>?</template>
                </div>

                <template v-if="locale === 'ru'">
                    <h3 v-if="prs.prs_title">{{prs.prs_title}}</h3>
                    <div><b>{{prs.prs_profstat}}</b></div>

                    <Tags :ids="prs.prs_type_id"
                          :locale="locale"
                          v-if="prs.prs_type_id"></Tags>

                    <table>
                        <tr>
                            <td>{{$t('card.bDate')}}:</td>
                            <td>
                                <template v-if="prs.birthDateFormatted">
                                    {{prs.birthDateFormatted | date}}
                                </template>
                                <template v-else-if="prs.prs_b_date">
                                    {{prs.prs_b_date | date}}
                                </template>
                                <template v-else>
                                    {{$t('card.unknown')}}
                                </template>
                            </td>
                        </tr>
                        <tr v-if="prs.bDateOld">
                            <td>{{$t('card.bDateOld')}}:</td>
                            <td>{{prs.bDateOld | date}}</td>
                        </tr>
                        <tr v-if="prs.prs_b_town || prs.prs_b_country">
                            <td>{{$t('card.bPlace')}}:</td>
                            <td>
                                <span class="commaBefore">{{prs.prs_b_town}}</span>
                                <span class="commaBefore">{{prs.prs_b_country}}</span>
                            </td>
                        </tr>
                        <tr v-if="!(prs.prs_death_date || '').includes('н.в')">
                            <td>{{$t('card.dDate')}}:</td>
                            <td>
                                <template v-if="prs.deathDateFormatted">
                                    {{prs.deathDateFormatted | date}}
                                </template>
                                <template v-else-if="prs.prs_death_date">
                                    {{prs.prs_death_date | date}}
                                </template>
                                <template v-else>
                                    {{$t('card.unknown')}}
                                </template>
                            </td>
                        </tr>
                        <tr v-if="prs.prs_death_town || prs.prs_death_country">
                            <td>
                                <template v-if="grPlaceEQdPlace">
                                    {{$t('card.grDPlace')}}:
                                </template>
                                <template v-else>
                                    {{$t('card.dPlace')}}:
                                </template>
                            </td>
                            <td>
                                <span class="commaBefore">{{prs.prs_death_town}}</span>
                                <span class="commaBefore">{{prs.prs_death_country}}</span>
                            </td>
                        </tr>
                        <tr v-if="(prs.prs_gr_town || prs.prs_gr_country) && !grPlaceEQdPlace">
                            <td>{{$t('card.grPlace')}}:</td>
                            <td>
                                <span class="commaBefore">{{prs.prs_gr_town}}</span>
                                <span class="commaBefore">{{prs.prs_gr_country}}</span>
                            </td>
                        </tr>
                        <tr v-if="prs.prs_graveyard">
                            <td>{{$t('card.graveyard')}}:</td>
                            <td>{{prs.prs_graveyard}}</td>
                        </tr>
                        <tr v-if="prs.prs_grv_coords">
                            <td>{{$t('card.grCoords')}}:</td>
                            <td>{{prs.prs_grv_coords}}</td>
                        </tr>
                    </table>
                    <div class="bio" v-if="prs.prs_bio">
                        <div class="subtitle">{{$t('card.bio')}}</div>
                        <div class="textarea" v-html="prs.prs_bio"></div>
                    </div>


                    <div class="biblio" v-if="prs.prs_biblio">
                        <div class="subtitle">{{$t('card.biblio')}}</div>
                        <div class="textarea" v-html="prs.prs_biblio"></div>
                    </div>
                </template>

                <template v-else>
                    <h3 v-if="prs.prs_F_alt">{{prs.prs_F_alt}}</h3>
                    <h3 v-if="prs.prs_title_en">{{prs.prs_title_en}}</h3>
                    <div><b>{{prs.prs_profstat_en}}</b></div>

                    <Tags :ids="prs.prs_type_id" v-if="prs.prs_type_id"></Tags>

                    <table>
                        <tr v-if="prs.prs_b_date">
                            <td>{{$t('card.bDate')}}:</td>
                            <td>
                                <template v-if="prs.birthDateFormatted">
                                    {{prs.birthDateFormatted | date}}
                                </template>
                                <template v-else>
                                    {{prs.prs_b_date | date}}
                                </template>
                            </td>
                        </tr>
                        <tr v-if="prs.bDateOld">
                            <td>{{$t('card.bDateOld')}}:</td>
                            <td>{{prs.bDateOld | date}}</td>
                        </tr>
                        <tr v-if="prs.prs_b_town_en || prs.prs_b_country_en">
                            <td>{{$t('card.bPlace')}}:</td>
                            <td>{{prs.prs_b_town_en}} {{prs.prs_b_country_en}}</td>
                        </tr>
                        <tr v-if="prs.prs_death_date">
                            <td>{{$t('card.dDate')}}:</td>
                            <td>
                                <template v-if="prs.deathDateFormatted">
                                    {{prs.deathDateFormatted | date}}
                                </template>
                                <template v-else-if="prs.prs_death_date">
                                    {{prs.prs_death_date | date}}
                                </template>
                                <template v-else>
                                    {{$t('card.unknown')}}
                                </template>
                            </td>
                        </tr>
                        <tr v-if="prs.prs_death_town_en || prs.prs_death_country_en">
                            <td>
                                {{$t('card.dPlace')}}:
                            </td>
                            <td>
                                <span class="commaBefore">{{prs.prs_death_town_en}}</span>
                                <span class="commaBefore">{{prs.prs_death_country_en}}</span>
                            </td>
                        </tr>
                        <tr v-if="(prs.prs_gr_town_en || prs.prs_gr_country_en)">
                            <td>{{$t('card.grPlace')}}:</td>
                            <td>
                                <span class="commaBefore">{{prs.prs_gr_town_en}}</span>
                                <span class="commaBefore">{{prs.prs_gr_country_en}}</span>
                            </td>
                        </tr>
                        <tr v-if="prs.prs_graveyard_en">
                            <td>{{$t('card.graveyard')}}:</td>
                            <td>{{prs.prs_graveyard_en}}</td>
                        </tr>

                        <tr v-if="prs.prs_grv_coords_en">
                            <td>{{$t('card.grCoords')}}:</td>
                            <td>{{prs.prs_grv_coords_en}}</td>
                        </tr>
                    </table>

                    <div class="bio" v-if="prs.prs_bio_en">
                        <div class="subtitle">{{$t('card.bio')}}</div>
                        <div class="textarea" v-html="prs.prs_bio_en"></div>
                    </div>


                    <div class="biblio" v-if="prs.prs_biblio_en">
                        <div class="subtitle">{{$t('card.biblio')}}</div>
                        <div class="textarea" v-html="prs.prs_biblio_en"></div>
                    </div>
                </template>

                <div class="docs-items" v-if="prs.doc && prs.doc.length">
                    <div class="subtitle">{{$t('card.docs')}}</div>
                    <div class="item" v-for="(doc,dk) in prs.doc" :key="dk">
                        <a :href="doc.fil_src" target="_blank" class="title">{{doc.fil_title}}</a>

                        <div class="desc textarea">{{doc.fil_desc}}</div>
                    </div>
                </div>

                <div class="links" v-if="prs.links && prs.links.length">
                    <div class="subtitle">{{$t('card.links')}}</div>
                    <ul class="link-items dashed">
                        <li v-for="link in prs.links" :key="link.lnk_id" class="pointer"
                            @click="$store.commit('showMdxPage', {show:true, id: link.lnk_right_id})">
                            {{encTitle(link.lnk_right_id)}}
                        </li>
                    </ul>

                </div>

                <div v-if="isAdmin" class="adminInfo">
                    <div class="subtitle mt-0"><i class="fas fa-info-circle me-2"></i>System info</div>
                    <table>
                        <tr>
                            <td>Created:</td>
                            <td>
                                {{prs.prs_reg_date | date2}}
                            </td>
                            <td>
                                {{prs.prs_createdBy}}
                                <a :href="`mailto:${prs.prs_creator_email}`" v-if="prs.prs_creator_email">({{prs.prs_creator_email}})</a>
                            </td>
                        </tr>
                        <tr>
                            <td>Updated:</td>
                            <td>
                                {{prs.prs_update | date2}}
                            </td>
                            <td>
                                {{prs.prs_updatedBy}}
                            </td>
                        </tr>
                    </table>
                </div>
                <!--div class="links">
                    <div class="subtitle">Ссылки на другие ресурсы</div>
                    <ul class="link-items dashed">
                        <li>
                            <a href="{{url}}" target="_blank">{{url}}</a>
                        </li>
                    </ul>
                </div-->
            </div>
        </div>
    </div>
</template>

<script>
    import Tags from "./widgets/Tags";

    export default {
        name: "PersonPage",
        components: {Tags},
        props: {},
        data() {
            return {
                prs: {},
                load: {
                    card: true
                },
                show: {
                    card: false,
                },
            }
        },
        computed: {
            locale() {
                return this.$store.getters.locale;
            },
            isAdmin() {
                return this.$store.getters.isAdmin;
            },
            personCard() {
                return this.$store.getters.personCard;
            },
            encyclopedia() {
                return this.$store.getters.encyclopediaTree;
            },
            refreshIt() {
                return this.$store.getters.personCard.refresh;
            },
            grPlaceEQdPlace() {
                let prs = this.prs;
                return prs ? prs.prs_death_town === prs.prs_gr_town
                    && prs.prs_death_country === prs.prs_gr_country : false
            },
            route_id() {
                return this.$route.params.id;
            },
            mrX() {
                return this.$isDev ? 'https://rusabroad.ru/images/avatar1.svg' : '/images/avatar1.svg';
            },
            prsImg() {
                return this.$isDev ? 'https://rusabroad.ru/' + this.prs.prs_img : this.prs.prs_img
            },
        },
        watch: {
            refreshIt(newVal) {
                if (newVal) {
                    this.$nextTick(() => {
                        this.refresh();
                        this.$store.commit("personCard", {refresh: false});
                    });
                }
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.refresh();
                this.$store.commit("personCard", {refresh: false});
            });

        },
        filters: {
            date(val) {
                return (val && val.length > 7 && !val.match(/[А-Яа-яA-Za-z]/)) ? moment(val).format('DD MMMM YYYY') : val;
            },
            date2(val) {
                return (val && val.length > 7 && !val.match(/[А-Яа-яA-Za-z]/)) ? moment(val).format('DD MMM YYYY - HH:mm') : val;
            }
        },
        methods: {
            refresh() {
                let self = this,
                    query = {
                        data: {
                            mod: 'show_person',
                            prs_id: self.personCard.personToShow.prs_id || self.route_id,
                            // prs_id: self.person.prs_id
                        }
                    };
                self.load.card = true;
                self.prs = {};
                self.$nextTick(() => {
                    // console.log('prs', this.prs);
                    self.$req(query).then(resp => {
                        // console.log('Person:::', resp);
                        self.prs = resp;
                        self.load.card = false;
                    });
                });
            },
            close() {
                this.prs = {};
                this.$store.commit("personCard", {show: false});

            },
            handleEditPerson(prs) {
                this.$nextTick(() => {
                    this.$store.commit("editPerson", prs);
                });
            },
            handlePutFlower() {
                let self = this,
                    query = {
                        data: {
                            mod: 'putFlower',
                            prs_id: self.prs.prs_id
                        }
                    };
                self.$req(query).then((resp, textStatus, request) => {
                    // console.log('flower:', resp);
                    self.prs.prs_flowers = resp.num
                })

            },
            printCard() {
                this.$utils.printElem('.person-container');
                // let content = $('.person-container');
                // content.appendTo($('body'));
                // window.print();
            },
            encTitle(id) {
                return ((this.$store.getters.encyclopediaTree.titles || {})[id] || {})[this.locale];
            },
        }
    }
</script>

<style lang="scss">
    @import "../assets/variables";

    .PersonCard {
        text-align: left;

        &.personPage {
            min-height: 100vh;

            .person-container {
                max-width: 1250px;
                margin: 0 auto;
            }
        }

        .person-container {
            display: flex;
            //flex-wrap: wrap;
            h1 {
                font-size: 1.8em;
                margin-bottom: 7px;
            }

            .img {
                flex-shrink: 0;
                display: flex;
                //align-items: flex-start;
                flex-direction: column;
                max-width: 250px;
                padding-right: 30px;

                .el-image {
                    border-radius: 5px;

                    &.gotDesc {
                        border-radius: 5px 5px 0 0;
                    }

                    & + .el-image {
                        margin-top: 25px;
                    }

                    & + .img-desc {
                        margin-top: 0;
                    }
                }

                img {
                    /*margin: 0 0 20px 0;*/
                    max-width: 230px;
                }

                .el-image__inner {
                    height: auto;
                }

                .img-desc {
                    font-size: 13px;
                    font-weight: 700;
                    background: $color-primary-light;
                    border-radius: 0 0 5px 5px;
                    padding: 3px 7px;
                    margin-top: -7px;
                    font-family: $font-sans;
                    line-height: 1.4;
                }

            }

            .img-items {
                /*margin-top: 25px;*/
                .item {
                    margin-top: 25px;
                }

                .title {
                    font-size: 13px;
                    font-weight: 700;
                }
            }

            .image-error-slot {
                width: 230px;
                margin: 0 auto;
            }

            .text {
                width: 100%;

                > div, table {
                    margin-bottom: 7px;
                }

                .subtitle {
                    font-size: 1rem;
                    margin: 30px 0 7px;
                    font-weight: 600;
                }
            }

            .title {
                font-weight: 600;
                font-size: 120%;
            }

            .bio, .biblio {
                font-size: 14px;
                line-height: 1.7888;
            }

            table {
                td {
                    padding: 5px 30px 5px 0;
                    vertical-align: top;

                    &:first-child {
                        white-space: nowrap;
                    }
                }
            }

            .link-items {
                font-size: 14px;
                padding: 1px 0 0 15px;

                li {
                    & + li {
                        margin-top: 10px;
                    }
                }
            }
        }

        h2 {
            justify-content: space-between;

            .control {
                display: flex;
                align-items: center;
                font-size: .85rem;

                > * {
                    opacity: .7;

                }
            }

            .visits {
                display: inline-flex;
                align-items: center;
                height: 32px;
                padding: 0 7px;
                border-radius: 3px;
            }

            .flower {
                cursor: pointer;
                margin-right: 20px;
                padding: 7px 12px;
                transition: all .1s ease;

                &:hover {
                    opacity: 1;
                }


                img {
                    transform: rotate(45deg);
                    height: 23px !important;
                }
            }
        }

        .adminInfo {
            margin-top: 30px;
            background: $color-primary-light;
            padding: 7px 15px;

            td {
                font-size: 13px;
                padding: 3px 12px 3px 0;
            }
        }
    }
</style>