import Vue from 'vue';
// import _ from 'lodash'
import axios from "axios";

const Req = Vue.prototype.$req = function (params = {}) {
    let serverUrl = 'rusabroad.ru',
        procPath = "/proc",
        requestUrl = window.location.host === serverUrl ? procPath : 'https://' + serverUrl + procPath;

    params.data.isDev = window.location.host === '127.0.0.1:8080';

    let _query = {
        url: requestUrl,
        // headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
        type: params.method ? params.method : 'POST',
        data: params.data,
        dataType: 'json',
    }
    if (params.contentType) {
        _query.contentType = params.contentType;
    }
    return $.ajax(_query);

    // let _data = new FormData();
    // _.forEach(params, (it, i) => {
    //     _data.append(i,it);
    // })
    // return axios.post(requestUrl, _data);

    // let data = _.map(params.data, (it, i) =>  (i + '=' + it)).join('&'),
    //     postUrl = 'https://' + serverUrl + procPath,
    //     requestUrl = 'https://api.codetabs.com/v1/proxy?quest=' + serverUrl + procPath + '?' + data;
    //
    // return new Promise((resolve, reject) => {
    //     fetch(postUrl, {
    //         method: 'POST',
    //         body: JSON.stringify(params.data)
    //     }).then(response => response.json()).then(json => {
    //         resolve(json);
    //         reject(new Error("Req problem..."));
    //     });
    // fetch(requestUrl).then(response => response.json()).then(json => {
    //     resolve(json);
    //     reject(new Error("Req problem..."));
    // });
    // })

};

export default Req;
