import Vue from 'vue';
import axios from "axios";
import store from '@/store';
import req from "../req";

function numberFormat(val) {
    return String(val).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
}

const Utils = Vue.prototype.$utils = Object.freeze({
    numbers: {
        format: numberFormat
    },
    bytes: {
        humanizeBytes(bytes) {
            if (!bytes) {
                return '';
            }
            bytes = bytes && parseInt(bytes);
            const kb = 1024, mb = kb * kb, gb = mb * kb, tb = gb * kb;
            if (!bytes || bytes < kb) {
                return `${bytes} B`;
            } else if (bytes < mb) {
                return `${(bytes / kb).toFixed(2)} KB`;
            } else if (bytes < gb) {
                return `${(bytes / mb).toFixed(2)} MB`;
            } else if (bytes < tb) {
                return `${(bytes / gb).toFixed(2)} GB`;
            }
            return `${(bytes / tb).toFixed(2)} TB`;
        }
    },
    getMdxPage(params) {
        if (params.id || params.alias) {
            let query = {
                data: {
                    mod: 'get_mdxPage',
                    alias: params.alias,
                    id: params.id,
                    locale: store.getters.locale,
                }
            };
            return req(query).then(resp => {
                return resp;
            });
        }
    },
    getSelectionText() {
        let text = "";
        if (window.getSelection) {
            text = window.getSelection().toString();
        } else if (document.selection && document.selection.type !== "Control") {
            text = document.selection.createRange().text;
        }
        return text;
    },
    validateEmail(email) {
        return (/^[A-zА-я]+([.-]?[A-zА-я]+)*@[A-zА-я]+([.-]?[A-zА-я]+)*\.([A-zА-я]{2,20})+$/.test(email));
    },
    br2nl(val) {
        return (val || '').replace(/<br>/g, "\r\n");
    },
    roundX(x, d = 0) {
        let n = Math.pow(10, parseInt(d));
        return Math.round(parseFloat(x) * n) / n;
    },
    toFin(num, dec = 0) { // форматирование сумм в виде XXX XXX XXX
        if (num) {
            num = parseFloat(num);
            if (Math.abs(num) >= 100) {
                dec = 0;
            } else if (Math.abs(num) >= 10) {
                dec = 1;
            }
            return num ? this.roundX(num, dec).toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ') : 0;
        } else {
            return 0;
        }
    },
    toFinHuman(num, dec = 0) { // форматирование сумм в human формате: 5K, 100M
        let suffix = '',
            out = {value: '0', suffix: ''};
        if (num) {
            num = parseFloat(num);
            if (Math.abs(num) >= 1000000000) {
                suffix = "B";
                num = num / 1000000000;
                dec = 1;
            } else if (Math.abs(num) >= 1000000) {
                suffix = "M";
                num = num / 1000000;
                dec = 1;
            } else if (Math.abs(num) >= 1000) {
                suffix = "k";
                num = num / 1000;
                dec = 1;
            } else if (Math.abs(num) >= 10) {
                dec = 1;
            }
            out.value = num ? this.roundX(num, dec).toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ') : 0;
        } else {
            out.value = 0;
        }
        out.suffix = suffix;
        out.number = suffix ? out.value + " " + suffix : out.value;
        out.numberSpanned = suffix ? out.value + '<span class="numSuffix">' + suffix + '</span>' : out.value;
        return out;
    },
    fromFinHuman(num) {
        let out = parseFloat(num.replace(/\s/gi, '').replace(/[A-z]/gi, '').replace(',', '.')) || 1,
            letters = [{
                letter: 'k',
                value: 1000
            }, {
                letter: 'm',
                value: 1000000
            }, {
                letter: 'b',
                value: 1000000000
            }]
        letters.forEach((it) => {
            let re = new RegExp(it.letter, "gi"),
                count = num.match(re) ? num.match(re).length : 0;
            if (count) {
                out = out * Math.pow(it.value, count);
            }
        })
        return out;
    },
    stripTags(text) {
        return text.replace(/(<([^>]+)>)/ig, "");
    },
    getCookies() {
        let out = {},
            cooks = document.cookie.split('; ');

        cooks.forEach(function (it) {
            let el = it.replace(' ', '').split('=');
            out[el[0]] = el[1];
        });
        return out;
    },
    getRandom(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min; //Максимум и минимум включаются
    },
    printElem(elem) {
        let w = window.open(),
            baseUrl = window.location.origin;
        let is_chrome = function () {
            return Boolean(window.chrome);
        }
        w.document.write(`
                <base href="${baseUrl}">
                <style>
                    body{
                        font-size: 15px;
                        font-family: sans-serif;
                    }
                    h2, h3{
                        margin: 0 0 15px 0;
                    }
                    .img{
                        float: right;
                        margin: 0 0 10px 15px;
                    }
                    .img .el-image + .el-image{
                        margin-top: 15px;
                    }
                    .img-items{
                        margin-top: 15px;
                    }
                    .img-items .item + .item{
                        margin-top: 15px;
                    }

                    img{
                        max-width: 150px;
                        height: auto;
                        width: auto;
                    }
                    .img-desc{
                        width: 150px;
                        margin: 5px 0 15px;
                        font-size: 12px;
                        line-height: 1.15;
                    }
                    .textarea{
                        white-space: pre-line;
                        line-height: 1.45;
                    }
                    .el-button, .control{
                        display: none;
                    }
                    .years{
                        margin-bottom: 12px;
                    }
                    .Tags{
                        margin: 12px 0 10px;
                    }
                    .el-tag{
                        display: inline-flex;
                        margin-right: 10px;
                        padding: 3px 7px;
                        border: 1px solid #333;
                        border-radius: 4px;
                        font-size: 13px;
                    }
                    .subtitle{
                        font-weight: 600;
                        margin: 20px 0 5px;
                        font-size: 16px;
                    }
                    td{
                        padding: 3px 10px 3px 0;
                        font-size: 14px;
                    }
                    td:first-child{
                        font-weight: 600;
                        padding-right: 20px;
                    }
                    .commaBefore:not(:empty) + .commaBefore:before {
                        content: ', ';
                    }
                    .commaBefore:empty {
                      display: none;
                    }

                </style>
                `);
        w.document.write($(elem).html());
        w.document.close();
        if (is_chrome()) {
            setTimeout(() => {
                w.print();
                setTimeout(() => {
                    w.close();
                }, 500)
            }, 1000)

        } else {
            setTimeout(() => {
                w.print();
                w.close();
            }, 200)
        }
    },
});

export default Utils;
