<template>
    <div class="Feedback">
        <el-input v-model="name" :placeholder="$t('card.yourName')" class="mb-2"></el-input>
        <el-input v-model="email" :placeholder="$t('card.yourEmail')" class="mb-2"></el-input>
        <el-input v-model="text" type="textarea" :rows="6" class="mb-2"></el-input>
        <el-button type="primary" @click="send">{{$t('label.submit')}}</el-button>

        <el-dialog
                :title="$t('messages.thankYou')"
                :visible.sync="dialogVisible"
                width="30%">
                        <span>{{$t('messages.messageSent')}}</span>
                        <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">OK</el-button>
              </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "Feedback",
        props: {},
        data() {
            return {
                email: '',
                name: '',
                text: '',
                dialogVisible: false
            }
        },
        computed: {
            locale() {
                return this.$store.getters.locale;
            },
            isEn() {
                return this.$store.getters.locale === 'en'
            }
        },
        watch: {
            locale() {
                this.refresh();
            }
        },
        mounted() {
        },
        methods: {
            refresh() {
                let self = this;
            },
            send() {
                let self = this,
                    query = {
                        data: {
                            mod: 'sendMsg',
                            name: self.name,
                            email: self.email,
                            text: self.text,
                        }

                    };
                self.$req(query).then(resp => {
                    console.log('resp');
                    self.dialogVisible = true;
                    self.text = '';

                })

            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../../assets/variables";

    .Feedback {
        max-width: 600px;
    }
</style>