<template>
    <div class="MdxCard">
        <el-drawer
                :title="pageTitle"
                :visible.sync="mdxPage.show"
                size="50%"
                direction="rtl">
            <div class="Inner">
                <div class="wrap" ref="wrap">
                    <mdx-page
                            type="article"
                            :alias="alias"
                            :id="id"
                            @pageTitle="handlePageTitle"
                    ></mdx-page>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
    import MdxPage from "../components/widgets/MdxPage";

    export default {
        name: "MdxCard",
        components: {MdxPage},
        props: {
        },
        data() {
            return {
                load: {
                    card: true,
                    enc: true,
                },
                pageTitle: '',
                alias: '',
                id: ''
            }
        },
        computed: {
            locale() {
                return this.$store.getters.locale;
            },
            mdxPage() {
                return this.$store.getters.mdxPage;
            },
            pageId() {
                return this.$store.getters.mdxPage.id;
            },
            pageAlias() {
                return this.$store.getters.mdxPage.alias;
            },
            isEn() {
                return this.$store.getters.locale === 'en'
            },
        },
        watch: {
            pageId(newVal) {
                if (newVal) {
                    this.$nextTick(() => {
                        this.refresh();
                    });
                }
            },
            pageAlias(newVal) {
                if (newVal) {
                    this.$nextTick(() => {
                        this.refresh();
                    });
                }
            }

        },

        mounted() {
            let self = this;
        },
        methods: {
            refresh() {
                let self = this;
                self.load.card = true;
                self.alias = self.pageAlias || '';
                self.id = self.pageId || '';
            },
            handleShow(status) {
                this.$store.commit("showMdxPage", {show: status});
            },
            handlePageTitle(title){
              this.pageTitle = title;
            },
            encTitle(id) {
                return ((this.$store.getters.encyclopediaTree.titles || {})[id] || {})[this.locale];
            },

        }

    }
</script>

<style lang="scss">
    @import "../assets/variables";

    .MdxCard {

        .editor-container {
            font-family: $font-sans;

            input, textarea, .el-button {
                font-family: $font-sans;
            }

            .el-input--small {
                font-size: 14px;
            }

            .personName {
                font-size: 20px;
                font-weight: 700;
                margin: -7px 0 10px;
                display: flex;
                justify-content: space-between;
            }

            .table {
                font-size: 14px;

                td {
                    vertical-align: middle;
                }

                .subtitle {
                    font-size: 1.15rem;
                    font-weight: 700;
                    font-variant: all-small-caps;
                    color: #000;
                    background: #f0f0f0;
                    margin: 0;
                    padding: 8px 10px 10px !important;
                    line-height: 1;
                }
            }

            .el-select {
                width: 100%;
            }

            .subtitle {
                background: $color-primary;
                color: #fff;
                font-size: 15px;
                font-weight: 400;
                padding: 5px 10px;
                margin-bottom: 7px;
            }

            .photo-block {
                display: flex;
                gap: 20px;

                > div {
                    border: 1px solid $color-primary;
                    border-radius: 5px;
                    margin-bottom: 20px;
                }

                .photo-items {
                    display: flex;
                    margin-bottom: 5px;
                    flex-wrap: wrap;
                    padding: 5px 15px 0;

                    .item {
                        margin: 0 10px 10px 0;

                        > div {
                            text-align: center;
                        }
                    }
                }

                div.el-upload.el-upload--picture-card {
                    margin-bottom: 7px;
                }

                .el-upload-list--picture-card {
                    .el-upload-list__item-thumbnail {
                        height: auto;
                    }

                    .el-upload-list__item {
                        display: inline-flex;
                        align-items: center;
                    }
                }
            }
        }

        .obj-links {
            .title {
                font-weight: 700;
                margin-bottom: 10px;
            }


            > div {
                & + div {
                    margin-top: 20px;
                    padding-top: 10px;
                    border-top: $border;
                }
            }
        }

        .prs-links > div,
        .addLink {
            display: flex;
            align-items: center;
            width: 600px;
            margin: 15px 0 0 20px;
        }

        .prs-links > div {
            font-size: 14px;
            margin-top: 10px;
        }

    }
</style>