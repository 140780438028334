<template>
    <div class="EditPerson">
        <el-drawer
                :title="prs.prs_id ? $t('label.editPerson') : $t('label.addPerson')"
                :visible.sync="personCard.editorShow"
                size="60%"
                direction="rtl"
                v-loading="load.card || load.enc">
            <div class="creator-data" v-show="!isValidCreator">
                <div class="desc" v-html="$t('card.enterYourContacts')"></div>
                <div class="flex-center">
                    <label>{{$t('card.yourName')}}: </label>
                    <el-input v-model="prs.prs_createdBy"></el-input>

                    <label>{{$t('card.yourEmail')}}: </label>
                    <el-input v-model="prs.prs_creator_email" type="email">
                    </el-input>
                    <el-button type="primary" @click="handleCreatorData()">{{$t('save')}}</el-button>
                </div>
            </div>
            <div class="editor-container" v-show="isValidCreator">
                <div class="personName">
                    <div v-if="isEn">{{prs.prs_F_en}} {{prs.prs_I_en}} {{prs.prs_O_en}}</div>
                    <div v-else>{{prs.prs_F}} {{prs.prs_I}} {{prs.prs_O}}</div>
                    <div v-if="isAdmin" class="top-control">
                        <el-select v-model="prs.prs_pubstat" class="me-2"
                                   @change="handleChangePubStat">
                            <el-option label="Опубликован" value=""></el-option>
                            <el-option label="Требуется модерация" value="need moderation"></el-option>
                        </el-select>

                        <el-button type="danger" plain
                                   @click="handleDelete({type:'person'})">
                            <i class="el-icon-delete"></i>
                            {{$t('delete')}}
                        </el-button>

                        <el-button type="primary"
                                   plain
                                   @click="handleSave">
                            <i class="el-icon-check"></i>
                            {{$t('save')}}
                        </el-button>
                    </div>
                </div>

                <el-tabs type="card" @tab-click="handleTab()" v-model="activeTab">
                    <el-tab-pane :label="$t('label.personalData')">
                        <table class="table">
                            <thead>
                            <tr>
                                <th>{{$t('label.field')}}</th>
                                <th>{{$t('label.russian')}}</th>
                                <th>English</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{{$t('label.category')}}</td>
                                <td colspan="2">
                                    <el-select v-model="prs.prs_type_id"
                                               multiple filterable clearable
                                               :placeholder="$t('label.category')">
                                        <el-option v-for="(opt, key) in categories"
                                                   :key="key"
                                                   :label="isEn ? opt.longtitle : opt.pagetitle"
                                                   :value="opt.id"
                                        ></el-option>
                                    </el-select>
                                </td>
                            </tr>
                            <tr>
                                <td>{{$t('label.sName')}}</td>
                                <td>
                                    <el-input v-model="prs.prs_F"></el-input>
                                </td>
                                <td>
                                    <el-input v-model="prs.prs_F_en"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <td>{{$t('label.fName')}}</td>
                                <td>
                                    <el-input v-model="prs.prs_I"></el-input>
                                </td>
                                <td>
                                    <el-input v-model="prs.prs_I_en"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <td>{{$t('label.mName')}}</td>
                                <td>
                                    <el-input v-model="prs.prs_O"></el-input>
                                </td>
                                <td>
                                    <el-input v-model="prs.prs_O_en"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <td>{{$t('label.altName')}}</td>
                                <td colspan="2">
                                    <el-input v-model="prs.prs_name_alt"></el-input>
                                </td>
                            </tr>
                            <!--tr>
                                <td>
                                    Титул/звание
                                    <div class="small">(князь, барон, p.h.d., м.н.с...)</div>
                                </td>
                                <td>
                                    <el-input v-model="prs.prs_title"></el-input>
                                </td>
                                <td>
                                    <el-input v-model="prs.prs_title_en"></el-input>
                                </td>
                            </tr-->
                            <tr>
                                <td>
                                    {{$t('label.profStat')}}
                                </td>
                                <td>
                                    <el-input type="textarea" :rows="3" v-model="prs.prs_profstat"></el-input>
                                </td>
                                <td>
                                    <el-input type="textarea" :rows="3" v-model="prs.prs_profstat_en"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3" class="subtitle">{{$t('label.birth')}}</td>
                            </tr>
                            <tr>
                                <td>
                                    {{$t('label.bDate')}}
                                    <div class="small">{{$t('label.dateFormatDesc')}}</div>
                                </td>
                                <td colspan="2">
                                    <el-input v-model="prs.prs_b_date"
                                              placeholder="12.02.1900, 1898-01-30, 1889"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{$t('label.town')}}
                                </td>
                                <td>
                                    <el-input v-model="prs.prs_b_town"></el-input>
                                </td>
                                <td>
                                    <el-input v-model="prs.prs_b_town_en"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{$t('label.country')}}
                                </td>
                                <td>
                                    <el-input v-model="prs.prs_b_country"></el-input>
                                </td>
                                <td>
                                    <el-input v-model="prs.prs_b_country_en"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3" class="subtitle">{{$t('label.death')}}</td>
                            </tr>
                            <tr>
                                <td>
                                    {{$t('label.dDate')}}
                                    <div class="small">{{$t('label.dateFormatDesc')}}</div>
                                </td>
                                <td colspan="2">
                                    <el-input v-model="prs.prs_death_date"
                                              placeholder="12.02.1900, 1898-01-30, 1889"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{$t('label.town')}}
                                </td>
                                <td>
                                    <el-input v-model="prs.prs_death_town"></el-input>
                                </td>
                                <td>
                                    <el-input v-model="prs.prs_death_town_en"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{$t('label.country')}}
                                </td>
                                <td>
                                    <el-input v-model="prs.prs_death_country"></el-input>
                                </td>
                                <td>
                                    <el-input v-model="prs.prs_death_country_en"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3" class="subtitle">{{$t('label.burial')}}</td>
                            </tr>
                            <tr>
                                <td>
                                    {{$t('label.town')}}
                                </td>
                                <td>
                                    <el-input v-model="prs.prs_gr_town"></el-input>
                                </td>
                                <td>
                                    <el-input v-model="prs.prs_gr_town_en"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{$t('label.country')}}
                                </td>
                                <td>
                                    <el-input v-model="prs.prs_gr_country"></el-input>
                                </td>
                                <td>
                                    <el-input v-model="prs.prs_gr_country_en"></el-input>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    {{$t('label.graveyard')}}
                                </td>
                                <td>
                                    <el-input v-model="prs.prs_graveyard"></el-input>
                                </td>
                                <td>
                                    <el-input v-model="prs.prs_graveyard_en"></el-input>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    {{$t('label.grvCoords')}}
                                </td>
                                <td>
                                    <el-input v-model="prs.prs_grv_coords"></el-input>
                                </td>
                                <td>
                                    <el-input v-model="prs.prs_grv_coords_en"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3" class="subtitle">{{$t('card.bio')}}</td>
                            </tr>
                            <tr>
                                <td colspan="3">
                                    <div class="w-cols">
                                        <div>
                                            <div class="mb-1">{{$t('label.russian')}}</div>
                                            <el-input type="textarea" :rows="12" v-model="prs.prs_bio"></el-input>
                                        </div>
                                        <div>
                                            <div class="mb-1">English</div>
                                            <el-input type="textarea" :rows="12" v-model="prs.prs_bio_en"></el-input>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3" class="subtitle">{{$t('card.biblio')}}</td>
                            </tr>
                            <tr>
                                <td colspan="3">
                                    <div class="w-cols">
                                        <div>
                                            <div class="mb-1">{{$t('label.russian')}}</div>
                                            <el-input type="textarea" :rows="12" v-model="prs.prs_biblio"></el-input>
                                        </div>
                                        <div>
                                            <div class="mb-1">English</div>
                                            <el-input type="textarea" :rows="12" v-model="prs.prs_biblio_en"></el-input>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </el-tab-pane>
                    <el-tab-pane :label="$t('label.photosFiles')">
                        <div class="photo-block">
                            <div>
                                <div class="subtitle">{{$t('card.mainPhoto')}}</div>
                                <div class="photo-items">
                                    <el-upload
                                            list-type="picture-card"
                                            :action="procUrl"
                                            :data="{mod: 'uploadFile', prs_id: prs.prs_id, file_type:'prs_img'}"
                                            :file-list="fileList.main"
                                            :limit="2"
                                            :before-upload="beforeFileUpload"
                                            :on-exceed="handleExceed"
                                            :before-remove="beforeRemoveFile"
                                            :on-success="handleSuccessfulUpload">
                                        <el-button size="small" type="primary">{{$t('label.browse')}}</el-button>
                                    </el-upload>
                                </div>
                            </div>
                            <div>
                                <div class="subtitle">{{$t('card.grvPhoto')}}</div>
                                <div class="photo-items">
                                    <div class="item">
                                        <el-upload
                                                list-type="picture-card"
                                                :action="procUrl"
                                                :data="{mod: 'uploadFile', prs_id: prs.prs_id, file_type:'prs_grv_img'}"
                                                :file-list="fileList.grave"
                                                :limit="2"
                                                :before-upload="beforeFileUpload"
                                                :on-exceed="handleExceed"
                                                :before-remove="beforeRemoveFile"
                                                :on-success="handleSuccessfulUpload">
                                            <el-button size="small" type="primary">{{$t('label.browse')}}</el-button>
                                        </el-upload>
                                        <el-input v-model="prs.prs_grv_img_desc"
                                                  :placeholder="$t('placeholders.imgDesc')"></el-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="photo-block" v-if="false">
                            <div class="w-100">
                                <div class="subtitle">Дополнительные фото</div>
                                <div class="photo-items">
                                    <div class="item dopPhotos">
                                        <el-upload
                                                list-type="picture-card"
                                                :action="procUrl"
                                                :data="{mod: 'uploadFile', prs_id: prs.prs_id, file_type:'prs_dop_images'}"
                                                :file-list="fileList.dop"
                                                :before-upload="beforeFileUpload"
                                                :on-exceed="handleExceed"
                                                :before-remove="beforeRemoveFile"
                                                :on-success="updateFL">
                                            <el-button size="small" type="primary">{{$t('label.browse')}}</el-button>
                                        </el-upload>
                                    </div>
                                </div>
                                <pre>
                                {{fileList.dop}}
                            </pre>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane :label="$t('label.linksToObjects')">
                        <div class="obj-links">
                            <div v-for="cat in encyclopedia.list" :key="cat.id">
                                <div class="title" v-html="isEn ? cat.longtitle : cat.pagetitle"></div>
                                <div class="prs-links">
                                    <div v-for="link in prs.links" :key="link.id" v-if="link.lnk_parent_id === cat.id">
                                        <i class="far fa-link me-2"></i>
                                        <span v-html="encTitle(link.lnk_right_id)"></span>

                                        <el-button class="ms-auto text-white" type="text" size="mini"
                                                   @click="handleDelete({type:'link', delId: link.lnk_id, 'delKey': 'lnk'})">
                                            <i class="fas fa-times"></i>
                                        </el-button>
                                    </div>
                                </div>
                                <div class="addLink">
                                    <el-select v-model="addLink[cat.id]" class="me-2"
                                               clearable
                                               :placeholder="$t('placeholders.addLink')">
                                        <el-option
                                                v-for="opt in cat.pages" :key="opt.id"
                                                :label="isEn ? opt.longtitle : opt.pagetitle" :value="opt.id"
                                                :disabled="rightIds.includes(opt.id)">
                                        </el-option>
                                    </el-select>
                                    <el-button type="primary" plain @click="handleAddLink(cat.id)"><i
                                            class="fas fa-save"></i></el-button>
                                </div>
                            </div>
                        </div>

                    </el-tab-pane>
                </el-tabs>
                <div class="footer">
                    <div>
                        <el-button type="primary"
                                   size="medium"
                                   @click="handleSave">
                            <i class="el-icon-check"></i>
                            {{$t('save')}}
                        </el-button>
                        <el-button class="ms-3" type="text" size="medium" @click="handleShow(false)">
                            <i class="el-icon-close"></i>
                            {{$t('close')}}
                        </el-button>

                    </div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>

    export default {
        name: "EditPerson",
        components: {},
        props: {
            person: {
                type: Object,
                default: () => ({})
            },
        },
        data() {
            return {
                prs: {
                    links: [],
                    prs_id: ''
                },
                mode: '',
                isValidCreator: true,
                changePubStat: '',
                activeTab: '0',
                lastTab: '0',
                rightIds: [],
                requiredFields: [
                    'prs_F'
                ],
                fileList: {
                    main: [],
                    grave: [],
                    dop: []
                },
                addLink: {},
                load: {
                    card: true,
                    enc: true,
                },
                show: {
                    card: false,
                },
                total: {
                    prs: 0,
                    grv: 0,
                    img: 0,
                }
            }
        },
        computed: {
            locale() {
                return this.$store.getters.locale;
            },
            categories() {
                return this.$store.getters.initData.categories;
            },
            personCard() {
                return this.$store.getters.personCard;
            },
            encyclopedia() {
                return this.$store.getters.encyclopediaTree;
            },
            refreshIt() {
                return this.$store.getters.personCard.editorRefresh;
            },
            procUrl(){
                return this.$hostUrl + 'proc';
            },
            isEn() {
                return this.$store.getters.locale === 'en'
            },
            isAdmin() {
                return this.$store.getters.isAdmin;
            },
            mrX() {
                return this.$isDev ? 'https://rusabroad.ru/images/avatar1.svg' : '/images/avatar1.svg';
            }
        },
        filters: {
            br2nl(val) {
                return val.replace(/<br>/g, "\r\n");
            },
        },
        watch: {
            refreshIt(newVal) {
                if (newVal) {
                    this.$nextTick(() => {
                        this.refresh();
                        this.$store.commit("personCard", {editorRefresh: false});
                    });
                }
            }
        },

        mounted() {
            let self = this;
            self.$store.dispatch('getEncyclopedia').then(() => {
                self.load.enc = false;
            });
        },
        methods: {
            refresh() {
                let self = this,
                    query = {
                        data: {
                            mod: 'show_person',
                            prs_id: self.personCard.personToShow.prs_id,
                        }
                    };
                self.load.card = true;
                self.prs = {
                    link: []
                };
                self.activeTab = '0';
                self.handleShow(true);
                self.$nextTick(() => {
                    document.querySelector('.el-tabs__content').scrollTop = 0;
                    self.prs.changePubStat = '';
                    if (self.personCard.personToShow.prs_id) {

                        //------- Edit Person -------

                        self.$req(query).then(resp => {
                            // console.log('Person@Edit:', resp);
                            resp.prs_type_id = resp.prs_type_id ? resp.prs_type_id.replace(/\s/g, '').split(',')
                                : self.personCard.personToShow.prs_type_id ? [self.personCard.personToShow.prs_type_id]
                                    : [];
                            resp.prs_bio = self.$utils.br2nl(resp.prs_bio);
                            resp.prs_bio_en = self.$utils.br2nl(resp.prs_bio_en);
                            resp.prs_biblio = self.$utils.br2nl(resp.prs_biblio);
                            resp.prs_biblio_en = self.$utils.br2nl(resp.prs_biblio_en);
                            self.prs = resp;

                            self.mode = 'editPerson';

                            self.fileList.main = resp.prs_img ? [{
                                name: 'prs_img:' + resp.prs_id,
                                url: self.$isDev ? 'https://rusabroad.ru/' + resp.prs_img : resp.prs_img
                            }] : [];
                            self.fileList.grave = resp.prs_grv_img ? [{
                                name: 'prs_grv_img:' + resp.prs_id,
                                url: self.$isDev ? 'https://rusemigration-online.com/' + resp.prs_grv_img : resp.prs_grv_img
                            }] : [];
                            self.rightIds = _.map(self.prs.links, 'lnk_right_id');
                            self.load.card = false;

                        });

                    } else {

                        //------- New Person -------

                        self.mode = 'newPerson';
                        let stored = _.cloneDeep(self.personCard.personToShow);
                        if (stored.prs_createdBy) self.prs.prs_createdBy = stored.prs_createdBy;
                        if (stored.prs_creator_email) self.prs.prs_creator_email = stored.prs_creator_email;
                        self.isValidCreator = !!self.isAdmin || (self.prs.prs_createdBy && self.prs.prs_creator_email);
                        if (stored.prs_type_id) {
                            self.prs.prs_type_id = [stored.prs_type_id]
                        }
                        self.$nextTick(() => {
                            self.prs = _.cloneDeep(self.prs);
                            self.fileList = {
                                main: [],
                                grave: [],
                                dop: []
                            };

                        })
                        self.load.card = false;
                    }
                });
            },
            savePerson() {
                let self = this,
                    _data = {};

                Object.assign(_data, self.prs, {'mod': 'editPersData'});
                _data.prs_type_id = _data.prs_type_id.filter(it => it).join(',');
                delete _data.prs_img;
                delete _data.prs_img_thumb;
                delete _data.prs_grv_img;
                delete _data.prs_grv_img_thumb;
                let query = {
                    data: _data
                };
                // console.log('::: Saving ::: ', query);

                self.$req(query).then(resp => {
                    // console.log('== Saved == ', resp);
                    if (!self.prs.prs_id) {
                        self.prs.prs_id = resp['prs_id'];
                        self.prs = _.cloneDeep(self.prs);
                    }
                    self.$store.commit('personCard', {refresh: true});
                });
            },
            handleSave() {
                let self = this;
                self.prs.changePubStat = self.changePubStat;
                self.prs.mode = self.mode;
                self.savePerson();
                // self.handleShow(false);
                self.$message({
                    message: 'Данные сохранены.',
                    type: 'success'
                });
                self.$store.dispatch('getAlphaData', self.locale);
                if (!self.isAdmin && self.mode === 'newPerson') {
                    self.$confirm(self.$t('messages.addAnotherPerson'), self.$t('messages.personCreated'), {
                        customClass: 'htmlText',
                        confirmButtonText: self.$t('yes'),
                        cancelButtonText: self.$t('no'),
                        dangerouslyUseHTMLString: true,
                        type: 'info'
                    }).then(() => {
                        let prs = {
                            prs_createdBy: self.prs.prs_createdBy,
                            prs_creator_email: self.prs.prs_creator_email,
                        };
                        self.$nextTick(() => {
                            self.$store.commit("editPerson", prs);
                        });
                    }).catch(() => {
                    });
                }
            },
            handleDelete(params) {
                let self = this,
                    type = params.type;
                let delTo = {
                    person: {
                        confirm: 'Вы точно хотите удалить персону?',
                        delKey: 'prs',
                        delId: self.prs.prs_id
                    },
                    image: {
                        confirm: 'Удалить это изображение?',
                        delKey: params.delKey,
                        delId: self.prs.prs_id
                    },
                    link: {
                        confirm: 'Удалить эту связь с объектом?',
                        delKey: params.delKey,
                        delId: params.delId
                    },
                }
                return this.$confirm(delTo[type].confirm, 'Внимание!', {
                    confirmButtonText: 'Удалить',
                    confirmButtonClass: 'bg-danger',
                    cancelButtonText: 'Отмена',
                    type: 'warning'
                }).then(() => {
                    this.$req({
                        data: {
                            mod: 'delUni',
                            del_id: delTo[type].delId,
                            del_key: delTo[type].delKey
                        }
                    }).then(resp => {
                        if (resp.success) {
                            this.$message({
                                type: 'success',
                                message: resp.success
                            });
                            if (type === 'person') {
                                self.handleShow(false);
                                self.$store.commit('personCard', {show: false});

                            }
                            if (type === 'link') {
                                self.prs.links = self.prs.links.filter(it => it.lnk_id != params.delId)
                                self.rightIds = _.map(self.prs.links, 'lnk_right_id');

                            }
                        } else if (resp.error) {
                            this.$message({
                                type: 'error',
                                message: resp.error
                            });
                        }
                    })
                }).catch(() => {
                });
            },
            handleAddLink(catId) {
                let self = this,
                    query = {
                        mod: 'editLinks',
                        lnk_parent_id: catId,
                        lnk_left_type: 'person',
                        lnk_left_id: self.prs.prs_id,
                        lnk_left_title: self.prs.prs_F + ' ' + self.prs.prs_I,
                        lnk_right_type: 'encyclopedia',
                        lnk_right_id: self.addLink[catId],
                        lnk_right_title: self.encyclopedia.titles[self.prs.id],
                    };
                // console.log('add id:', catId, self.addLink[catId]);
                self.$req({
                    data: query
                }).then(resp => {
                    self.prs.links = self.prs.links || [];
                    self.prs.links.push(query);
                    self.addLink[catId] = '';
                    self.rightIds = _.map(self.prs.links, 'lnk_right_id');
                    console.log(self.rightIds, resp);
                });

            },
            beforeRemoveFile(file) {
                let self = this,
                    _name = file.name.split(':'),
                    _key = _name[0],
                    _id = _name[1];
                return new Promise((resolve, reject) => {
                    self.$confirm(`Удалить это изобаражение?`, {
                        confirmButtonText: 'Удалить',
                        confirmButtonClass: 'bg-danger',
                        cancelButtonText: 'Отмена',
                        type: 'warning'
                    }).then(() => {
                        self.$req({
                            data: {
                                mod: 'delUni',
                                del_id: _id,
                                del_key: _key
                            }
                        }).then(resp => {
                            if (resp.success) {
                                self.$message({
                                    type: 'success',
                                    message: resp.success
                                });
                                resolve();
                            } else if (resp.error) {
                                self.$message({
                                    type: 'error',
                                    message: resp.error
                                });
                                reject();
                            }
                        });
                    }).catch(() => {
                        reject();
                    });
                });
            },
            handleSuccessfulUpload(response, file, fileList) {
                console.log(response, file, fileList);
                let self = this;
                if (fileList && fileList.length > 1) {
                    let name = fileList[0].name
                    fileList.shift();
                    fileList[0].name = name;
                    file.name = name;
                }
                // if (response && response.success) {
                //     let _name = fileList[0].name.split(':');
                //     self.prs[_name[0]] = response.success.path;
                //     delete self.prs[_name[0] + '_thumb'];
                // }
            },
            handleExceed(files, fileList) {
                this.$message.warning(this.$t('card.deleteBeforeUpload'));
            },
            updateFL(response, file, fileList) {
                console.log('FL', fileList);
                this.fileList.dop = fileList;
            },
            handleShow(status) {
                this.$store.commit("personCard", {editorShow: status});
            },
            beforeFileUpload(file) {
                let self = this;
                const isFormat = ['image/jpeg', 'image/png', 'image/gif'].includes(file.type);
                const maxSize = 3.5;
                const isSize = file.size / 1024 / 1024 < maxSize;

                if (!isFormat) {
                    this.$message.error(self.$t('card.imgFormat'));
                }
                // if (!isSize) {
                //     this.$message.error(self.$t('card.fileMaxSize') + maxSize + ' Mb!');
                // }
                // return isFormat && isSize;
                return isFormat;
            },
            handleTab() {
                if (this.activeTab !== '0') {
                    this.lastTab = this.activeTab;
                    this.savePerson();
                }
            },
            handleChangePubStat(value) {
                this.changePubStat = value ? value : 'published';
            },
            handleCreatorData() {
                let self = this;
                self.isValidCreator = !!self.prs.prs_createdBy && self.$utils.validateEmail(self.prs.prs_creator_email);
            },
            encTitle(id) {
                return ((this.$store.getters.encyclopediaTree.titles || {})[id] || {})[this.locale];
            },

        }

    }
</script>

<style lang="scss">
    @import "../assets/variables";

    .EditPerson {

        .editor-container {
            font-family: $font-sans;

            input, textarea, .el-button {
                font-family: $font-sans;
            }

            .el-input--small {
                font-size: 14px;
            }

            .personName {
                font-size: 20px;
                font-weight: 700;
                margin: -7px 0 10px;
                display: flex;
                justify-content: space-between;
            }

            .table {
                font-size: 14px;

                td {
                    vertical-align: middle;
                }

                .subtitle {
                    font-size: 1.15rem;
                    font-weight: 700;
                    font-variant: all-small-caps;
                    color: #000;
                    background: #f0f0f0;
                    margin: 0;
                    padding: 8px 10px 10px !important;
                    line-height: 1;
                }
            }

            .el-select {
                width: 100%;
            }

            .subtitle {
                background: $color-primary;
                color: #fff;
                font-size: 15px;
                font-weight: 400;
                padding: 5px 10px;
                margin-bottom: 7px;
            }

            .photo-block {
                display: flex;
                gap: 20px;

                > div {
                    border: 1px solid $color-primary;
                    border-radius: 5px;
                    margin-bottom: 20px;
                }

                .photo-items {
                    display: flex;
                    margin-bottom: 5px;
                    flex-wrap: wrap;
                    padding: 5px 15px 0;

                    .item {
                        margin: 0 10px 10px 0;

                        > div {
                            text-align: center;
                        }
                    }
                }

                div.el-upload.el-upload--picture-card {
                    margin-bottom: 7px;
                }

                .el-upload-list--picture-card {
                    .el-upload-list__item-thumbnail {
                        height: auto;
                    }

                    .el-upload-list__item {
                        display: inline-flex;
                        align-items: center;
                    }
                }
            }
        }

        .obj-links {
            .title {
                font-weight: 700;
                margin-bottom: 10px;
            }


            > div {
                & + div {
                    margin-top: 20px;
                    padding-top: 10px;
                    border-top: $border;
                }
            }
        }

        .prs-links > div,
        .addLink {
            display: flex;
            align-items: center;
            width: 600px;
            margin: 15px 0 0 20px;
        }

        .prs-links > div {
            font-size: 14px;
            margin-top: 7px;
            padding: 3px 15px;
            background: $color-primary;
            color: #fff;
            border-radius: 4px;
        }

        .creator-data {
            margin-bottom: 25px;
            border: $border-light;
            background: #fff;
            padding: 15px 20px;
            font-size: 14px;

            .desc {
                width: 900px;
            }

            label {
                font-size: 14px;
                margin-right: 7px;
            }

            .el-input {
                width: 250px;
                margin-right: 20px;
            }
        }

        .top-control {
            display: flex;
            align-items: center;

            .el-select {
                font-size: 13px;
            }
        }
    }
</style>